import Api from 'lib/Api';
import { SagaIterator } from 'redux-saga';
import { put, select, takeLatest } from 'redux-saga/effects';
import { getTimesheetSettings } from 'state/Account';
import { formatError } from 'state/helpers';
import { apiWithConfirmCall } from 'state/ProcessApiRequest';
import { ApiWithConfirmReturnType, SagaActionFromCreator } from 'type';
import * as actions from '../actions';

function* updateTimesheetSettings({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_TIMESHEET_SETTINGS_UPDATE.request
>) {
  try {
    const response: ApiWithConfirmReturnType<typeof Api.Account.update> =
      yield apiWithConfirmCall(Api.Account.update, {
        timesheet_settings: payload,
      });

    if (response) {
      yield put(
        actions.BOX_ACCOUNT_TIMESHEET_SETTINGS_UPDATE.success(response)
      );
    } else {
      yield put(actions.BOX_ACCOUNT_TIMESHEET_SETTINGS_UPDATE.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_TIMESHEET_SETTINGS_UPDATE.failure(formatError(error))
    );
  }
}

function* resetDefaultBreaksToDefaults({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_TIMESHEET_DEFAULT_BREAKS_RESET.request
>): SagaIterator {
  try {
    const settings: ReturnType<typeof getTimesheetSettings> = yield select(
      getTimesheetSettings
    );

    const response: ApiWithConfirmReturnType<typeof Api.Account.update> =
      yield apiWithConfirmCall(Api.Account.update, {
        timesheet_settings: {
          ...settings,
          ...payload,
          default_breaks_rules: null,
        },
      });

    if (response) {
      yield put(
        actions.BOX_ACCOUNT_TIMESHEET_DEFAULT_BREAKS_RESET.success(response)
      );
    } else {
      yield put(actions.BOX_ACCOUNT_TIMESHEET_DEFAULT_BREAKS_RESET.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_TIMESHEET_DEFAULT_BREAKS_RESET.failure(
        formatError(error)
      )
    );
  }
}

const updateSaveCustomBreaks = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_TIMESHEET_APPLY_CUSTOMISED_BREAKS.request
>): SagaIterator {
  try {
    const timesheetSettings: ReturnType<typeof getTimesheetSettings> =
      yield select(getTimesheetSettings);

    const response: ApiWithConfirmReturnType<typeof Api.Account.update> =
      yield apiWithConfirmCall(Api.Account.update, {
        timesheet_settings: {
          ...timesheetSettings,
          auto_breaks: true,
          breaks_from_rules: true,
          breaks_from_shift: false, // Added this as it doesn't get updated from true to false
          default_breaks_rules: payload,
        },
      });
    if (response) {
      yield put(
        actions.BOX_ACCOUNT_TIMESHEET_APPLY_CUSTOMISED_BREAKS.success(response)
      );
    } else {
      yield put(actions.BOX_ACCOUNT_TIMESHEET_APPLY_CUSTOMISED_BREAKS.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_TIMESHEET_APPLY_CUSTOMISED_BREAKS.failure(
        formatError(error)
      )
    );
    yield put(
      actions.BOX_ACCOUNT_TIMESHEET_SETTINGS_UPDATE.failure(formatError(error))
    );
  }
};

export default function* () {
  yield takeLatest(
    actions.BOX_ACCOUNT_TIMESHEET_SETTINGS_UPDATE.request,
    updateTimesheetSettings
  );
  yield takeLatest(
    actions.BOX_ACCOUNT_TIMESHEET_DEFAULT_BREAKS_RESET.request,
    resetDefaultBreaksToDefaults
  );
  yield takeLatest(
    actions.BOX_ACCOUNT_TIMESHEET_APPLY_CUSTOMISED_BREAKS.request,
    updateSaveCustomBreaks
  );
}
