import { Select } from 'elmo-elements';
import { Text } from 'elmo-elements';
import { useSelector } from 'react-redux';
import { Project } from 'state/Projects/types';
import { getProjects } from 'state/Projects/selectors';

type Props = {
  defaultValue?: string | null;
  onProjectChange: (projectId: string) => void;
  isEditable: boolean;
  isEditing: boolean;
  onClick?: () => void;
  hideLabel?: boolean;
};

const emptyPlaceholder = '-';
const emptyProject = { label: 'No project selected', value: '' };

export default function ProjectsDropdown({
  defaultValue = emptyProject.value,
  onProjectChange,
  isEditable,
  isEditing,
  onClick,
  hideLabel = false,
}: Props) {
  const projects = useSelector(getProjects);
  const incompleteProjects = projects.filter((project) => !project.completed);

  const projectOptions = incompleteProjects.map((project: Project) => ({
    label: project.name,
    value: project.id,
  }));

  // add empty project option so the user can choose not to add a project/remove a project when editing
  const projectOptionsWithEmptyOption = [emptyProject, ...projectOptions];

  const selectedProjectOption = projectOptionsWithEmptyOption.find(
    (project) => {
      if (defaultValue === null) {
        return project.value === '';
      }
      return project.value === defaultValue;
    }
  );

  const nonEditViewText = () => {
    if (selectedProjectOption) {
      if (selectedProjectOption.value === '') {
        return emptyPlaceholder;
      } else {
        return selectedProjectOption.label;
      }
    } else {
      return emptyPlaceholder;
    }
  };

  return (
    <div
      className={isEditable && !isEditing ? 'isEditable' : ''}
      onClick={() => {
        if (isEditable && !isEditing) {
          if (onClick) {
            onClick();
          }
        }
      }}
    >
      {!hideLabel ? (
        <Text size={'xs'} color={'gray'} id={'rostered-shifts-label'}>
          Project
        </Text>
      ) : null}

      {isEditing ? (
        <Select
          id="project"
          options={projectOptionsWithEmptyOption}
          onChange={(option) => onProjectChange(option.value)}
          name="project"
          ariaLabel="Please select"
          placeholder="Please select"
          isDisabled={projectOptionsWithEmptyOption.length < 2}
          defaultValue={
            projectOptionsWithEmptyOption.length < 2
              ? { label: 'No projects available', value: '' }
              : selectedProjectOption
          }
        />
      ) : (
        <div>{nonEditViewText()}</div>
      )}
    </div>
  );
}
