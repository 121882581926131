import { SagaIterator } from 'redux-saga';
import { call, takeEvery, put } from 'redux-saga/effects';
import { Api } from 'lib/Api';
import { processApiRequest } from 'state/ProcessApiRequest';
import { GET_PROJECTS_REQUEST, UPDATE_PROJECTS } from './actions';

export const getProjects = function* (): SagaIterator {
  try {
    const projects = yield call(processApiRequest, Api.Projects.get);
    yield put(UPDATE_PROJECTS(projects));
  } catch (error) {}
};

export const watchProjects = function* (): SagaIterator {
  yield takeEvery(GET_PROJECTS_REQUEST, getProjects);
};
