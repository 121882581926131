import React, { ReactText, Component } from 'react';
import { Props, State, DropDownProps, OwnProps } from './type';
import ReportForm from './view';
import moment from 'moment';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  BOX_REPORT_GENERATE_REPORT_REQUEST,
  BOX_REPORT_CLEAR_ERRORS,
} from 'state/Report';
import ErrorBox from 'element/ErrorBox';
import { privateRoutes } from 'routes';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  BOX_REPORTS_CREATE_MODAL_CLOSE,
  BOX_REPORTS_CREATE_REDIRECT_MODAL_CLOSE,
} from 'state/Reports';
import {
  getActiveUsersOnly,
  getArchivedUsersOnly,
  getAreaRelatedFilters,
  getDateRange,
  getFilteredAreaIds,
  getFilteredIds,
  getFilteredUsersByAreas,
  getFilteredUsersBySites,
  getIdsFromDropDown,
  getModalParams,
  getSelectedValues,
  getSiteRelatedFilters,
  prepareRequestData,
} from './helpers';
import _ from 'lodash';
import {
  activeRolesAsSelectOptionsArraySelector,
  activeAreasBySiteIdSelector,
  activeSitesAsSelectOptionsArraySelector,
  activeAreasAsSelectOptionsArraySelector,
  activeSitesSelector,
  activeAreasSelector,
} from 'state/AccountTree/selectors';
import { usersAsSelectOptionsArraySelector } from 'state/UsersCollection';
import { getDateFormat } from 'state/Account';
import { AccountTreeArea, SelectPropsOptionGrouped } from 'type';
import {
  AppBar,
  DialogContent,
  DialogTitle,
  Toolbar,
} from 'extended-oxygen-elements';
import { CloseIcon, PageDialog, PageDialogSubmit } from 'element';
import { IconButton } from 'oxygen-elements';

type ModalProps = Props & RouteComponentProps & OwnProps;

class ReportModal extends Component<ModalProps, State> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      isRequesting: false,
      isModalOpened: props.isCreateOpened,
      type: props.type,
      data: getModalParams(props.report, props.isEdit, props.type),
      errors: [],
      reportParams: {},
      showArchived: true,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps !== this.props) {
      if (this.props.errors.length) {
        this.setState({
          errors: this.props.errors,
          isRequesting: false,
        });
      }

      if (this.props.isCreateOpened !== prevProps.isCreateOpened) {
        this.setState({
          isRequesting: false,
          isModalOpened: this.props.isCreateOpened,
          data: getModalParams(
            this.props.report,
            this.props.isEdit,
            this.props.type
          ),
          type: this.props.type,
          errors: [],
          showArchived: this.props.report.extra.show_inactive_users,
        });
        this.props.clearErrors();
      }

      if (this.props.isRedirect === true && !this.props.isEdit) {
        this.props.history.push(privateRoutes.reports.routes.generate.path, {
          filters: this.state.reportParams,
        });
      }
    }
  }

  handleClick = (e: any) => {
    const data = prepareRequestData(
      {
        ...this.state.data,
        show_inactive_users: this.state.showArchived,
      },
      this.state.type,
      this.props.dateFormat
    );
    this.setState({
      isRequesting: true,
      reportParams: data,
    });
    this.props.generate(data);
  };

  handleOnChangeStatus = (e: any) => {
    this.setState({
      data: {
        ...this.state.data,
        status: e.value,
      },
    });
  };

  handleOnChangeSelect = (e: any, name: string) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          [name]: e.value,
        },
      },
      () => {
        if (name === 'period' && e.value !== 'custom') {
          const { toDate, fromDate, timeFrom, timeTo } = getDateRange(e.value);
          this.setState({
            data: {
              ...this.state.data,
              date_from: fromDate,
              date_to: toDate,
              time_from: timeFrom,
              time_to: timeTo,
            },
          });
        }
      }
    );
  };

  isDateValid = () => {
    const { period, date_to, date_from } = this.state.data;
    return period === 'custom' && (date_from === null || date_to === null);
  };

  handleOnChangeSelect2 = (options: DropDownProps[], name: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [name]: getIdsFromDropDown(options, name),
        },
      }),
      () => {
        if (name === 'areas' || name === 'sites') {
          this.filterSelectedRolesOnSiteAreaChanged();
        }
      }
    );
  };

  handleOnChangeTimeFormat = (value: ReactText) => {
    this.setState({
      data: {
        ...this.state.data,
        time_format: value.toString(),
      },
    });
  };

  handleChangePickers = (e: any, name: string) => {
    if (e === '' || e === null || e === undefined) {
      this.setState({
        data: {
          ...this.state.data,
          [name]: moment(e),
          period: 'custom',
        },
      });
    } else {
      const type = name.indexOf('date_') !== -1 ? 'datepicker' : 'timepicker';
      const value = type === 'datepicker' ? moment(e) : e;
      this.setState({
        data: {
          ...this.state.data,
          [name]: value,
          period: 'custom',
        },
      });
    }
  };

  handleOnChangeCheckbox = (e: any) => {
    const { name, checked } = e.target;
    this.setState({
      data: {
        ...this.state.data,
        [name]: checked,
      },
    });
  };

  removeInactive = () => {
    const { users } = this.state.data;
    const filteredUsers = getActiveUsersOnly(
      this.props.usersAsSelectOptionsArray
    );
    const usersIds = getFilteredIds(filteredUsers, users);
    this.setState({
      data: {
        ...this.state.data,
        users: usersIds,
      },
    });
  };

  filterSelectedRolesOnSiteAreaChanged = () => {
    const filteredRoles: string[] = [];
    const { roles, areas, sites } = this.state.data;
    if (areas.length) {
      const result = this.getAvailableRolesOptionsByAreas(areas);
      result.forEach((option) => {
        if (roles.includes(option.value)) {
          filteredRoles.push(option.value);
        }
      });
      return this.setSelectedRolesOnFilteredValues(filteredRoles);
    }

    if (sites.length) {
      const result = this.getAvailableRolesByFilteredSites(sites);
      result.map((option) => {
        if (roles.includes(option.value)) {
          filteredRoles.push(option.value);
        }
      });
      return this.setSelectedRolesOnFilteredValues(filteredRoles);
    }
  };

  getAvailableRolesByFilteredSites = (sites: string[]): DropDownProps[] => {
    const availableRoles = getSiteRelatedFilters(
      sites,
      this.props.areasBySiteId
    );
    return getSelectedValues(
      this.props.rolesAsSelectOptionsArray,
      _.uniq(availableRoles)
    );
  };

  setSelectedRolesOnFilteredValues = (roles: string[]) => {
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        roles: roles,
      },
    }));
  };

  handleOnChangeProjects = (projects: DropDownProps[]) => {
    const project_ids = projects.map(project => project.value);
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        projects: project_ids,
      },
    }));
  };

  handleOnChangeSites = (options: DropDownProps[]) => {
    const ids = getIdsFromDropDown(options);
    const { users, roles, areas } = this.filterAreasRolesAndUsers(ids);
    this.setState(
      {
        data: {
          ...this.state.data,
          sites: ids,
          users: users,
          roles: roles,
          areas: areas,
        },
      },
      () => this.filterSelectedRolesOnSiteAreaChanged()
    );
  };

  render() {
    const { name } = this.state.data;
    const { title } = this.props;

    return (
      <PageDialog
        fullScreen
        open={this.state.isModalOpened}
        id="create-new-report"
        onClose={this.props.closeCreateModal}
      >
        <AppBar
          sx={{ position: 'relative', background: '#fff', color: '#000' }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.props.closeCreateModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <div style={{ color: '#000', width: '100%' }}>
              <DialogTitle>{name ? name : title}</DialogTitle>
            </div>
            <PageDialogSubmit
              onClick={this.handleClick}
              loading={this.state.isRequesting}
              disabled={this.isDateValid()}
              id={'generate-report-btn'}
              fullWidth={false}
            >
              Generate
            </PageDialogSubmit>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ paddingTop: '20px' }}>
          <ErrorBox
            errors={this.props.errors}
            clearErrors={this.props.clearErrors}
          />
          <ReportForm
            data={this.state.data}
            handleChangePickers={this.handleChangePickers}
            handleOnChangeSelect={this.handleOnChangeSelect}
            handleOnChangeStatus={this.handleOnChangeStatus}
            handleOnChangeCheckbox={this.handleOnChangeCheckbox}
            handleOnChangeSelect2={this.handleOnChangeSelect2}
            handleOnChangeTimeFormat={this.handleOnChangeTimeFormat}
            handleOnChangeProjects={this.handleOnChangeProjects}
            type={this.state.type}
            showArchived={this.state.showArchived}
            handleOnChangeSites={this.handleOnChangeSites}
            rolesAsSelectOptionsArray={this.getRolesBySitesFilterOptions()}
            sitesAsSelectOptionsArray={this.props.sitesAsSelectOptionsArray}
            areasAsSelectOptionsArray={this.getAreasBySitesFilterOptions()}
            usersAsSelectOptionsArray={this.getUsersBySitesFilterOptions()}
            toggleArchived={this.toggleArchivedUsers}
            dateFormat={this.props.dateFormat}
          />
        </DialogContent>
      </PageDialog>
    );
  }

  private toggleArchivedUsers = () => {
    const show = !this.state.showArchived;
    this.setState((prevState) => ({
      ...prevState,
      showArchived: !this.state.showArchived,
      data: {
        ...prevState.data,
        show_inactive_users: !this.state.showArchived,
      },
    }));
    if (!show) {
      this.removeInactive();
    }
  };

  private filterAreasRolesAndUsers = (sites: string[]) => {
    const { users, roles, areas } = this.state.data;

    const areasOptions = this.getAreasBySitesFilterOptions(sites);
    const userOptions = this.getUsersBySitesFilterOptions(sites);
    const rolesOptions = this.getRolesBySitesFilterOptions(sites);

    return {
      users: getFilteredIds(userOptions, users),
      roles: getFilteredIds(rolesOptions, roles),
      areas: getFilteredAreaIds(areasOptions, areas),
    };
  };

  private getUserOptions = () => {
    return !this.state.showArchived
      ? getActiveUsersOnly(this.props.usersAsSelectOptionsArray)
      : this.props.usersAsSelectOptionsArray;
  };

  private getAvailableRolesOptionsByAreas = (areas: string[]) => {
    const availableRoles = getAreaRelatedFilters(this.props.areas, areas);
    return getSelectedValues(
      this.props.rolesAsSelectOptionsArray,
      _.uniq(availableRoles)
    );
  };

  private getRolesBySitesFilterOptions = (siteIds?: string[]) => {
    const sites = siteIds ? siteIds : this.state.data.sites;
    const { areas } = this.state.data;
    if (areas.length) {
      return this.getAvailableRolesOptionsByAreas(areas);
    }
    if (sites.length) {
      return this.getAvailableRolesByFilteredSites(sites);
    }
    return this.props.rolesAsSelectOptionsArray;
  };

  private getAreasBySitesFilterOptions = (siteIds?: string[]) => {
    const { sites, areasBySiteId } = this.props;
    const selectedSites = siteIds ? siteIds : this.state.data.sites;
    if (selectedSites.length) {
      const result: SelectPropsOptionGrouped[] = [];
      const getSiteIndex = (options: any, value: string) => {
        let exists = -1;
        options.forEach((option: any, index: number) => {
          if (option.label === value) {
            exists = index;
          }
        });
        return exists;
      };

      selectedSites.forEach((siteId) => {
        const areas: AccountTreeArea[] = areasBySiteId[siteId];
        if (areas) {
          areas.forEach((area: AccountTreeArea) => {
            const siteName = sites[siteId].name;
            const index = getSiteIndex(result, siteName);
            if (index > -1) {
              result[index].options.push({
                label: `${area.name}, ${siteName}`,
                value: area.id,
              });
            } else {
              result.push({
                label: `${siteName}`,
                options: [
                  {
                    label: `${area.name}, ${siteName}`,
                    value: area.id,
                  },
                ],
              });
            }
          });
        }
      });
      return result;
    }
    return this.props.areasAsSelectOptionsArray;
  };

  private getUsersBySitesFilterOptions = (siteIds?: string[]) => {
    const sites = siteIds || this.state.data.sites;
    const users = this.getUserOptions();
    const { areas } = this.state.data;

    if (areas.length) {
      return getFilteredUsersByAreas(areas, users);
    }

    if (sites.length) {
      return getFilteredUsersBySites(sites, users);
    }

    return users;
  };
}

const mapStateToProps = (state: StoreState) => ({
  report: state.report.report,
  generated: state.report.generatedReport,
  errors: state.report.errors,
  isCreateOpened: state.reports.createModal.isOpened,
  isRedirect: state.reports.createModal.redirectOnSuccess,
  rolesAsSelectOptionsArray: activeRolesAsSelectOptionsArraySelector(state),
  sitesAsSelectOptionsArray: activeSitesAsSelectOptionsArraySelector(state),
  areasAsSelectOptionsArray: activeAreasAsSelectOptionsArraySelector(state),
  usersAsSelectOptionsArray: usersAsSelectOptionsArraySelector(state),
  areasBySiteId: activeAreasBySiteIdSelector(state),
  dateFormat: getDateFormat(state),
  sites: activeSitesSelector(state),
  areas: activeAreasSelector(state),
});

export default connect(mapStateToProps, {
  generate: BOX_REPORT_GENERATE_REPORT_REQUEST,
  closeCreateModal: BOX_REPORTS_CREATE_MODAL_CLOSE,
  closeAndRedirect: BOX_REPORTS_CREATE_REDIRECT_MODAL_CLOSE,
  clearErrors: BOX_REPORT_CLEAR_ERRORS,
})(withRouter(ReportModal));
