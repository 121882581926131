import { NestedRoutes } from 'element';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { getUserFieldsRosterViewSettings } from 'state/Auth';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing/selectors';
import { StoreState } from 'state/types';
import {
  PreferencesDefaultRosterView,
  Route,
  StringMap,
  UserFieldsRosterViewSettings,
} from 'type';
import { RosterPageModals, RosterQueryWrapper } from './components';
import { GET_PROJECTS_REQUEST } from 'state/Projects/actions';

type StateProps = {
  view: PreferencesDefaultRosterView;
  userFieldsRosterViewSettings: UserFieldsRosterViewSettings | null;
  isCovidTracingMode: boolean;
};

type Props = {
  routes: StringMap<Route>;
  getProjects: () => void;
} & RouteComponentProps &
  StateProps;

export class Roster extends Component<Props, {}> {
  routeKeys: any = {};
  modifiedRoutes: any = this.props.routes;

  constructor(props: Props) {
    super(props);
    this.modifiedRoutes = props.routes;
    this.setKeys();
    this.modify();
  }

  componentDidMount() {
    this.props.getProjects();
  }

  componentDidUpdate() {
    this.modify();
  }

  modifyRoutesByAccountSettings = () => {
    const { modifiedRoutes, routeKeys } = this;
    const { view } = this.props;
    modifiedRoutes.fallBack.redirect = routeKeys.defaultPage.redirects[view];
    modifiedRoutes.rosterWeekView.routes.fallBack.redirect =
      routeKeys.weekDefault.redirects[view];
    modifiedRoutes.rosterDayView.routes.fallBack.redirect =
      routeKeys.dayDefault.redirects[view];
  };

  modifyRoutesByUserPreferences = () => {
    const { modifiedRoutes, routeKeys } = this;
    const {
      userFieldsRosterViewSettings: { page_type, view_type },
    } = this.props as any; // TODO double-check
    modifiedRoutes.fallBack.redirect =
      routeKeys.defaultPage.redirects[view_type];
    modifiedRoutes.rosterWeekView.routes.fallBack.redirect =
      routeKeys.weekDefault.redirects[view_type];
    modifiedRoutes.rosterDayView.routes.fallBack.redirect =
      routeKeys.dayDefault.redirects[view_type];
    if (page_type === 'day') {
      modifiedRoutes.fallBack.redirect =
        modifiedRoutes.fallBack.redirect.replace(/week-view/i, 'day-view');
    }
  };

  setKeys = () => {
    const { modifiedRoutes } = this;
    this.routeKeys = {
      defaultPage: modifiedRoutes.fallBack,
      weekDefault: modifiedRoutes.rosterWeekView.routes.fallBack,
      dayDefault: modifiedRoutes.rosterDayView.routes.fallBack,
    };
  };

  modify = () => {
    const { userFieldsRosterViewSettings } = this.props;
    const { modifiedRoutes } = this;

    if (modifiedRoutes.fallBack && modifiedRoutes.fallBack.redirects) {
      if (!userFieldsRosterViewSettings) {
        this.modifyRoutesByAccountSettings();
      } else {
        this.modifyRoutesByUserPreferences();
      }
    }
  };

  render() {
    return (
      <RosterQueryWrapper>
        <NestedRoutes routes={this.modifiedRoutes} />
        <RosterPageModals />
      </RosterQueryWrapper>
    );
  }
}

const mapDispatchToProps = {
  getProjects: GET_PROJECTS_REQUEST,
};

const mapStateToProps = (state: StoreState): StateProps => ({
  view: state.account.account.preferences.default_roster_view,
  userFieldsRosterViewSettings: getUserFieldsRosterViewSettings(state),
  isCovidTracingMode: getIsCovidModeEnabled(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Roster));
