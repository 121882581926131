import { SagaIterator } from 'redux-saga';
import { Moment } from 'moment';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Collection, SagaAction, ShiftBreak, ShiftTemplateBreak } from 'type';
import { SERVER_TIME_FORMAT } from 'lib/config';
import { BulkRosteredShiftsRequest } from 'lib/Api';
import { assertUnreachable, getRosteringPayload } from 'helpers/helpers';
import { formatError, getDefaultBreak } from 'state/helpers';
import {
  BOX_IS_LOADING_OFF,
  BOX_IS_LOADING_ON,
  ROSTER_SHIFT_INPUT,
} from 'state/IsLoading';
import { getAutoPublish, getIsDefaultBreaksApplied } from 'state/Account';
import { createRosteredShiftRequest } from 'state/RosteredShifts';
import { createTimesheetRequest } from 'state/TimesheetsCollection';
// Added approve mode handler here in order to remove circular dependency
// TODO think about how to refactor this
import {
  BOX_ROSTER_APPROVE_MODE_TOGGLE,
  BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN,
  getIsApprovalModeEnabled,
} from '../../Roster';
import {
  createShiftItemRequest,
  getDefaultBreak as getTemplateDefaultBreak,
  getTemplateId,
} from '../../EditTemplate';
import { CreateShiftPayload } from './types';
import * as actions from './actions';
import {
  getDayTimestamp,
  getSiteId,
  getType,
  siteTimezoneSelector,
} from './selectors';
import { getFlag } from '../../../Flags';

const createShift = function* ({
  payload: { user_id, area_id, role_id, range },
}: SagaAction<CreateShiftPayload>): SagaIterator {
  const { start, end } = range;

  yield put(BOX_IS_LOADING_ON(ROSTER_SHIFT_INPUT));

  const type: ReturnType<typeof getType> = yield select(getType);
  const site_id: ReturnType<typeof getSiteId> = yield select(getSiteId);

  const createActions: Collection<ReturnType<typeof getType>, () => void> = {
    rosteredShift: function* () {
      const breaks: ShiftBreak[] = [];
      const isAutoPublish = yield select(getAutoPublish);
      const isDefaultBreaks = yield select(getIsDefaultBreaksApplied);

      const shift = {
        user_id,
        start,
        end,
        area_id,
        role_id,
        template_id: null,
        breaks,
        event_id: null,
        notes: null,
      };

      const createPayload: BulkRosteredShiftsRequest = {
        shifts: [shift],
      };

      yield call(createRosteredShiftRequest, createPayload);
    },
    timesheet: function* () {
      yield call(createTimesheetRequest, {
        user_id,
        start,
        end,
        site_id,
        area_id,
        role_id,
        breaks: [],
        notes: null,
        project_id: null,
      });
    },
    templateShift: function* () {
      const day = yield select(getDayTimestamp);
      const templateId = yield select(getTemplateId);

      yield call(createShiftItemRequest, {
        role_id,
        area_id,
        user_id,
        day: +day + 1,
        start: start.format(SERVER_TIME_FORMAT),
        end: end.format(SERVER_TIME_FORMAT),
        breaks: [],
        tags: [],
        templateId,
        ignore_errors: false,
      });
    },
  };
  (window as any).inlineIsLoading = true;
  try {
    yield call(createActions[type]);
  } catch (error) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(error)));
  }
  yield put(actions.BOX_SHIFT_TIME_RANGE_INPUT_CREATE_FINISHED());
  yield put(BOX_IS_LOADING_OFF(ROSTER_SHIFT_INPUT));
  (window as any).inlineIsLoading = false;
};

const handleApproveMode = function* (): SagaIterator {
  const isApprovalModeEnabled = yield select(getIsApprovalModeEnabled);

  if (isApprovalModeEnabled) {
    yield put(actions.BOX_SHIFT_TIME_RANGE_INPUT_CLOSED());
  }
};

export const watchShiftTimeRangeInput = function* (): SagaIterator {
  yield takeLatest(
    actions.BOX_SHIFT_TIME_RANGE_INPUT_CREATE_REQUEST,
    createShift
  );
  yield takeLatest(BOX_ROSTER_APPROVE_MODE_TOGGLE, handleApproveMode);
};
