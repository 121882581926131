import { createReducer } from 'lib/store-utils';
import { defaultState } from './state';
import { ProjectsReducerState } from './types';
import { UPDATE_PROJECTS } from './actions';

export const projects = createReducer<ProjectsReducerState>({}, defaultState);

projects.on(UPDATE_PROJECTS, (state, payload): ProjectsReducerState => {
  return [...payload];
});
