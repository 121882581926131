import { call, put, race, select, take } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Moment } from 'moment';
import { get } from 'lodash';
import { SagaAction, UserFields } from 'type';
import { assertUnreachable } from 'helpers/helpers';
import { formatError, getDefaultBreak } from 'state/helpers';
import * as isLoading from 'state/IsLoading';
import {
  createRosteredShiftRequest,
  currentSiteTimezoneSelector,
  getSiteId,
} from 'state/RosteredShifts';
import { hasPermissionSelector } from 'state/Auth';
import { createTimesheetRequest } from 'state/TimesheetsCollection';
import {
  getAutoPublish,
  getDefaultBreaksRules,
  getIsDefaultBreaksApplied,
} from 'state/Account';
import {
  BOX_TIMESHEET_SHIFT_MODAL_CLOSE,
  BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_SUCCESS,
  BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID,
} from 'state/TimesheetModal/actions';
import { userListSelector } from 'state/UsersCollection/selectors';
import { BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN } from '../../../Roster/actions'; // TODO double check for circular dependency
import { getIsApprovalModeEnabled } from '../../../Roster/selectors';
import {
  BOX_ROSTER_SHIFT_MODAL_CLOSE,
  BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT_SUCCESS,
  BOX_ROSTER_SHIFT_MODAL_GET_BY_ID,
} from '../../../RosterShiftModal/actions'; // TODO double check for circular dependency
import {
  CreateShiftEndPayload,
  CreateShiftStartPayload,
  RosterDayViewCreatedShiftType,
} from '../../types';
import {
  BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_END,
  BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_SET_DATA,
  BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_CLEAR,
  BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_CLEAR,
} from '../../actions';
import { dstFallBackRangeSelector } from '../../selectors';
import { CreateShiftActions, ValidateShiftStartPayload } from './types';
import { expectedFlags } from '../../../../../feature-flags';
import { getFlag } from 'state/Flags';

export const createShiftModalActions: CreateShiftActions = {
  rosteredShift: {
    openModal: BOX_ROSTER_SHIFT_MODAL_GET_BY_ID,
    closeModal: BOX_ROSTER_SHIFT_MODAL_CLOSE,
    success: BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT_SUCCESS,
  },
  timesheet: {
    openModal: BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID,
    closeModal: BOX_TIMESHEET_SHIFT_MODAL_CLOSE,
    success: BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_SUCCESS,
  },
};

const validateByDstFallBackRange = function* ({
  start,
}: ValidateShiftStartPayload) {
  const dstFallBackRange: ReturnType<typeof dstFallBackRangeSelector> =
    yield select(dstFallBackRangeSelector);

  let notInDstFallBackRange = true;
  if (
    dstFallBackRange &&
    start.isBetween(dstFallBackRange.start, dstFallBackRange.end)
  ) {
    notInDstFallBackRange = false;
  }

  return notInDstFallBackRange;
};

export const validateShiftStart = function* (
  payload: ValidateShiftStartPayload
): SagaIterator {
  const isApprovalModeEnabled = yield select(getIsApprovalModeEnabled);

  if (isApprovalModeEnabled) {
    return false;
  }

  if (payload.type === 'timesheet') {
    const canEditTimesheet: ReturnType<typeof hasPermissionSelector> =
      yield select(hasPermissionSelector, 'roster.timesheet.edit');
    const notInDstFallBackRange = yield call(
      validateByDstFallBackRange,
      payload
    );

    return canEditTimesheet && notInDstFallBackRange;
  }

  // rosteredShift;
  const canEditRosteredShift = yield select(
    hasPermissionSelector,
    'roster.rosteredshift.edit'
  );
  const rosterStartNotInDstFallBackRange = yield call(
    validateByDstFallBackRange,
    payload
  );
  return canEditRosteredShift && rosterStartNotInDstFallBackRange;
};

export const createShiftWithoutModal = function* ({
  shiftPayload,
  type,
}: {
  shiftPayload: {
    user_id: string;
    role_id: string;
    area_id: string;
    start: Moment;
    end: Moment;
  };
  type: RosterDayViewCreatedShiftType;
}) {
  yield put(isLoading.BOX_IS_LOADING_GLOBAL_ON());
  try {
    switch (type) {
      case 'rosteredShift':
        const isAutoPublish = yield select(getAutoPublish);
        const hasDefaultBreaks = yield select(getIsDefaultBreaksApplied);
        yield call(createRosteredShiftRequest, {
          shifts: [
            {
              ...shiftPayload,
              template_id: null,
              event_id: null,
              breaks: [],
              notes: null,
            },
          ],
        });

        break;
      case 'timesheet':
        yield call(createTimesheetRequest, {
          ...shiftPayload,
          breaks: [],
          notes: null,
          project_id: null,
        });
        break;
      default:
        assertUnreachable(type);
    }

    yield put(BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_CLEAR());
    yield put(BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_CLEAR());
  } catch (error) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(error)));
  }
  yield put(
    BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_SET_DATA({
      shift: null,
    })
  );
  yield put(isLoading.BOX_IS_LOADING_GLOBAL_OFF());
};

type ShiftWithStartEnd = {
  id: string;
  start: Moment;
  end: Moment;
  user_id?: string | null;
  area_id?: string;
  role_id?: string;
};
export const createShiftWithModal = function* ({
  type,
  shiftWithStartEnd,
}: {
  type: RosterDayViewCreatedShiftType;
  shiftWithStartEnd: ShiftWithStartEnd;
}) {
  const modalActions = createShiftModalActions[type];
  yield put(modalActions.openModal(shiftWithStartEnd));

  const { success } = yield race({
    close: take(modalActions.closeModal),
    success: take(modalActions.success),
  });

  if (success) {
    yield put(BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_CLEAR());
    yield put(BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_CLEAR());
  }
  yield put(
    BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_SET_DATA({
      shift: null,
    })
  );
};

export const createShift = function* ({
  shiftWithStartEnd,
  type,
}: {
  shiftWithStartEnd: ShiftWithStartEnd;
  type: RosterDayViewCreatedShiftType;
}) {
  const users: ReturnType<typeof userListSelector> = yield select(
    userListSelector
  );

  const user: UserFields | undefined = get(
    users,
    `${shiftWithStartEnd.user_id}`
  );

  const siteId: ReturnType<typeof getSiteId> = yield select(getSiteId);

  const userRolesBySelectedSite = user
    ? user.user_roles.filter((userRole) => userRole.site_id === siteId)
    : [];
  if (
    userRolesBySelectedSite.length === 1 &&
    !shiftWithStartEnd.start.isSame(shiftWithStartEnd.end)
  ) {
    yield call(createShiftWithoutModal, {
      shiftPayload: {
        user_id: user.id,
        role_id: userRolesBySelectedSite[0].role_id,
        area_id: userRolesBySelectedSite[0].area_id,
        start: shiftWithStartEnd.start,
        end: shiftWithStartEnd.end,
      },
      type,
    });
  } else {
    yield call(createShiftWithModal, { type, shiftWithStartEnd });
  }
};

export const createShiftPayloadWithDragging = function* ({
  payload: { shift, clientX, pairIndex = NaN, type },
}: SagaAction<CreateShiftStartPayload>): SagaIterator {
  const isStartValid: boolean = yield call(validateShiftStart, {
    type,
    start: shift.start,
  });
  if (!isStartValid) {
    return;
  }

  const shiftWithStart = {
    ...shift,
    id: '',
  };

  yield put(
    BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_SET_DATA({
      clientX,
      pairIndex,
      shift: shiftWithStart,
      type,
    })
  );

  const {
    payload: { end },
  }: SagaAction<CreateShiftEndPayload> = yield take(
    BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_END
  );
  const shiftWithStartEnd = {
    ...shiftWithStart,
    end,
  };

  yield call(createShift, { shiftWithStartEnd, type });
};
