import React, { useState } from 'react';
import { Dictionary } from 'ts-essentials';
import { Route } from 'type';
import { BOX_SOCKETS_EMPLOYEE_DASHBOARD } from 'state/Sockets';
import { useConnectAction } from 'hooks';
import { NestedRoutes, NotificationsList } from 'element';
import { useDispatch } from 'react-redux';
import { GET_PROJECTS_REQUEST } from 'state/Projects/actions';

type EmployeeDashboardProps = {
  routes: Dictionary<Route>;
};

function EmployeeDashboard(props: EmployeeDashboardProps) {
  const dispatch = useDispatch();
  useState(() => {
    dispatch(GET_PROJECTS_REQUEST()); // get projects (needed in My Timesheets)
  });
  useConnectAction(BOX_SOCKETS_EMPLOYEE_DASHBOARD, undefined);

  return (
    <>
      <NestedRoutes {...props} />
      <NotificationsList type={'employee'} />
    </>
  );
}

export default EmployeeDashboard;
