import * as React from 'react';
import { FormProps, FormState } from './type';
import PeriodGroup from './components/PeriodGroup';
import LocationAreaRoleUserFilters from './components/LocationAreaRoleUserFilters';
import CommonExtraGroup from './components/CommonExtraGroup';
import TimesheetViewGroup from './components/TimesheetViewGroup';
import SaleExtraGroup from './components/SaleExtraGroup';
import CoverageViewGroup from './components/CoverageViewGroup';
import PeriodGroupRoster from './components/PeriodGroupRoster';
import RosterExtraGroup from './components/RosterExtraGroup';
import LocationAreaFilters from './components/LocationAreaFilters';
import LateExtraGroup from './components/LateExtraGroup';

class ReportForm extends React.Component<FormProps, FormState> {
  toggleArchived = () => {
    this.props.toggleArchived();
  };

  getPeriodGroup = () => {
    return (
      <PeriodGroup
        data={this.props.data}
        handleOnChangeSelect={this.props.handleOnChangeSelect}
        handleChangePickers={this.props.handleChangePickers}
      />
    );
  };

  getRosterPeriodGroup = () => {
    return (
      <PeriodGroupRoster
        data={this.props.data}
        handleOnChangeSelect={this.props.handleOnChangeSelect}
        handleChangePickers={this.props.handleChangePickers}
      />
    );
  };

  getCommonExtraGroup = () => {
    const { type } = this.props;
    return type === 'late' ? (
      <LateExtraGroup
        handleOnChangeSelect={this.props.handleOnChangeSelect}
        handleOnChangeCheckbox={this.props.handleOnChangeCheckbox}
        handleOnChangeTimeFormat={this.props.handleOnChangeTimeFormat}
        data={this.props.data}
      />
    ) : (
      <CommonExtraGroup
        handleOnChangeSelect={this.props.handleOnChangeSelect}
        handleOnChangeCheckbox={this.props.handleOnChangeCheckbox}
        handleOnChangeTimeFormat={this.props.handleOnChangeTimeFormat}
        data={this.props.data}
      />
    );
  };

  getRosterExtraGroup = () => {
    return (
      <>
        <h3>View</h3>
        <RosterExtraGroup
          handleOnChangeSelect={this.props.handleOnChangeSelect}
          handleOnChangeTimeFormat={this.props.handleOnChangeTimeFormat}
          data={this.props.data}
        />
      </>
    );
  };

  getTimesheetExtraGroup = () => {
    return (
      <TimesheetViewGroup
        handleOnChangeSelect={this.props.handleOnChangeSelect}
        handleOnChangeCheckbox={this.props.handleOnChangeCheckbox}
        handleOnChangeTimeFormat={this.props.handleOnChangeTimeFormat}
        handleOnChangeStatus={this.props.handleOnChangeStatus}
        data={this.props.data}
      />
    );
  };

  getSaleExtraGroup = () => {
    return (
      <SaleExtraGroup
        handleOnChangeSelect={this.props.handleOnChangeSelect}
        handleOnChangeTimeFormat={this.props.handleOnChangeTimeFormat}
        data={this.props.data}
      />
    );
  };

  getCoverageExtraGroup = () => {
    return (
      <CoverageViewGroup
        handleOnChangeCheckbox={this.props.handleOnChangeCheckbox}
        handleOnChangeSelect={this.props.handleOnChangeSelect}
        data={this.props.data}
      />
    );
  };

  getDataGroup = () => {
    return (
      <LocationAreaRoleUserFilters
        data={this.props.data}
        handleOnChangeSelect2={this.props.handleOnChangeSelect2}
        handleOnChangeSites={this.props.handleOnChangeSites}
        handleOnChangeProjects={this.props.handleOnChangeProjects}
        rolesAsSelectOptionsArray={this.props.rolesAsSelectOptionsArray}
        sitesAsSelectOptionsArray={this.props.sitesAsSelectOptionsArray}
        usersAsSelectOptionsArray={this.props.usersAsSelectOptionsArray}
        areasAsSelectOptionsArray={this.props.areasAsSelectOptionsArray}
        showArchived={this.props.showArchived}
        toggleArchived={this.toggleArchived}
      />
    );
  };

  getCommonTemplate = () => {
    return (
      <>
        {this.getPeriodGroup()}
        {this.getCommonExtraGroup()}
        {this.getDataGroup()}
      </>
    );
  };

  getRosterTemplate = () => {
    return (
      <>
        {this.getRosterPeriodGroup()}
        {this.getRosterExtraGroup()}
        <LocationAreaFilters
          data={this.props.data}
          handleOnChangeSelect2={this.props.handleOnChangeSelect2}
          handleOnChangeSites={this.props.handleOnChangeSites}
          sitesAsSelectOptionsArray={this.props.sitesAsSelectOptionsArray}
          areasAsSelectOptionsArray={this.props.areasAsSelectOptionsArray}
        />
      </>
    );
  };

  getTimesheetTemplate = () => {
    return (
      <>
        {this.getPeriodGroup()}
        {this.getTimesheetExtraGroup()}
        {this.getDataGroup()}
      </>
    );
  };

  getSaleTemplate = () => {
    return (
      <>
        {this.getPeriodGroup()}
        {this.getSaleExtraGroup()}
        {this.getDataGroup()}
      </>
    );
  };

  getEventTemplate = () => {
    return (
      <>
        {this.getPeriodGroup()}
        {this.getDataGroup()}
      </>
    );
  };

  getCoverageTemplate = () => {
    return (
      <>
        {this.getPeriodGroup()}
        {this.getCoverageExtraGroup()}
        {this.getDataGroup()}
      </>
    );
  };

  templateSelect() {
    switch (this.props.type) {
      case 'timesheet':
        return this.getTimesheetTemplate();

      case 'sale':
        return this.getSaleTemplate();

      case 'event':
        return this.getEventTemplate();

      case 'coverage':
        return this.getCoverageTemplate();

      case 'roster':
        return this.getRosterTemplate();

      default:
        return this.getCommonTemplate();
    }
  }

  render() {
    return this.templateSelect();
  }
}

export default ReportForm;
