import {
  AccountTreeArea,
  PreferencesDateFormat,
  Report,
  ReportType,
  SelectPropsOptionGrouped,
} from 'type/models';
import moment from 'moment';
import { Data, DropDownProps } from './type';
import _ from 'lodash';
import {
  groupByColumns,
  columnTypes,
  resultTypes,
} from 'state/Report/reportModalValues';
import { StringMap } from '../../../../type';

export const defaultParams: Data = {
  id: '',
  name: '',
  details: true,
  show_leave: false,
  include_completed_projects: false,
  show_self_assigned: false,
  group_by: '',
  order_by: '',
  show_approved_shifts: false,
  show_approved_timesheets: false,
  detailed_breaks: false,
  interval: '60',
  date_field_anniversary: false,
  datatype: '',
  show: 'all',
  sale_include: 'all',
  date_field_start: '03/23/2019',
  date_field: 'all',
  archived: 'all',
  shifttype: 'published',
  date_field_direction: 'on',
  time_format: 'HH:mm',
  period: 'yesterday',
  date_from: null,
  time_from: moment('03/13/2019 00:00', 'MM/DD/YYYY HH:mm'),
  date_to: null,
  time_to: moment('03/13/2019 00:00', 'MM/DD/YYYY HH:mm'),
  tag_type: '',
  sites: [],
  areas: [],
  roles: [],
  users: [],
  tags: [],
  fields: [],
  show_inactive_users: true,
  status: 'all',
  with_violations: false,
  project_ids: []
};
const getDefaultTagType = (isEdit: boolean, type: ReportType) => {
  return ['rostered_shift', 'no_show', 'transfer', 'coverage', 'tag'].indexOf(
    type
  ) !== -1 && !isEdit
    ? 'shift'
    : 'user';
};

const getExtraParamValue = (report: Report, isEdit: boolean, key: string) => {
  const extra: any = report.extra;
  const params: any = defaultParams;
  return isEdit && report.extra ? extra[key] : params[key];
};

const getGroupByValue = (report: Report, isEdit: boolean, type: ReportType) => {
  if (type === 'coverage') {
    return isEdit && report.extra
      ? report.extra.group_by
      : groupByColumns[0].value;
  }
  if (type === 'tag') {
    return isEdit && report.extra ? report.extra.group_by : 'site';
  }
  return getExtraParamValue(report, isEdit, 'group_by');
};

const getDataTypeValue = (
  report: Report,
  isEdit: boolean,
  type: ReportType
) => {
  if (type === 'coverage') {
    return isEdit && report.extra
      ? report.extra.datatype
      : columnTypes[0].value;
  }
  if (type === 'transfer') {
    return isEdit && report.extra
      ? report.extra.datatype
      : columnTypes[0].value;
  }
  if (type === 'tag') {
    return isEdit && report.extra
      ? report.extra.datatype
      : resultTypes[0].value;
  }
  return getExtraParamValue(report, isEdit, 'datatype');
};

export const getDateRange = (value: string) => {
  const periods: any = {
    today: 'today',
    this_week: 'this_week',
    yesterday: 'yesterday',
    tomorrow: 'tomorrow',
    last_week: 'last_week',
    next_week: 'next_week',
    last_2_weeks: 'last_2_weeks',
    last_7_days: 'last_7_days',
    this_month: 'this_month',
    last_month: 'last_month',
    last_30_days: 'last_30_days',
    next_30_days: 'next_30_days',
    this_year: 'this_year',
  };
  const val = periods[value];
  const today = moment().locale('en');
  let fromDate: any = null;
  let toDate: any = null;
  let timeFrom = moment(today).startOf('isoWeek').startOf('day');
  let timeTo = moment(today).endOf('isoWeek').endOf('day');
  if (val) {
    if (val === 'today') {
      fromDate = today.startOf('day');
      toDate = today.endOf('day');
    }
    if (val === 'this_week') {
      fromDate = moment(today).startOf('isoWeek').startOf('day');
      toDate = moment(today).endOf('isoWeek').endOf('day');
    }
    if (val === 'yesterday') {
      fromDate = moment(today).subtract(1, 'day').startOf('day');
      toDate = moment(today).subtract(1, 'day').endOf('day');
    }
    if (val === 'tomorrow') {
      fromDate = moment(today).add(1, 'day').startOf('day');
      toDate = moment(today).add(1, 'day').endOf('day');
    }
    if (val === 'last_week') {
      fromDate = moment(today)
        .subtract(7, 'days')
        .startOf('isoWeek')
        .startOf('day');
      toDate = moment(today).subtract(7, 'days').endOf('isoWeek').endOf('day');
    }
    if (val === 'next_week') {
      fromDate = moment(today).add(7, 'days').startOf('isoWeek').startOf('day');
      toDate = moment(today).add(7, 'days').endOf('isoWeek').endOf('day');
    }
    if (val === 'last_2_weeks') {
      fromDate = moment(today)
        .subtract(14, 'days')
        .startOf('isoWeek')
        .startOf('day');
      toDate = moment(today).subtract(7, 'days').endOf('isoWeek').endOf('day');
    }
    if (val === 'last_7_days') {
      fromDate = moment(today).subtract(7, 'days').startOf('day');
      toDate = moment(today).subtract(1, 'day').endOf('day');
    }
    if (val === 'this_month') {
      fromDate = moment(today).startOf('month').startOf('day');
      toDate = moment(today).endOf('month').endOf('day');
    }
    if (val === 'last_month') {
      fromDate = moment(today)
        .subtract(1, 'month')
        .startOf('month')
        .startOf('day');
      toDate = moment(today).subtract(1, 'month').endOf('month').endOf('day');
    }
    if (val === 'last_30_days') {
      fromDate = moment(today).subtract(30, 'days').startOf('day');
      toDate = moment(today).subtract(1, 'day').endOf('day');
    }
    if (val === 'next_30_days') {
      fromDate = moment(today).add(1, 'day').startOf('day');
      toDate = moment(today).add(30, 'days').endOf('day');
    }
    if (val === 'this_year') {
      fromDate = moment(today).startOf('year').startOf('day');
      toDate = moment(today).endOf('year').endOf('day');
    }
  }
  return {
    fromDate: fromDate,
    toDate: toDate,
    timeTo: timeTo,
    timeFrom: timeFrom,
  };
};

const getDefaultRangeValue = (value: string, key: string) => {
  const { toDate, fromDate, timeFrom, timeTo } = getDateRange(value);
  const values: any = {
    date_from: fromDate,
    date_to: toDate,
    time_to: timeTo,
    time_from: timeFrom,
  };
  return values[key];
};

const getPeriodDate = (date: any, value: string, key: string) => {
  if (date !== null && date) {
    return moment(date, 'YYYY-MM-DD HH:mm').toDate();
  }
  return getDefaultRangeValue(value, key);
};

const getPeriodTime = (date: any, keyName: string, value: string) => {
  if (date !== null && date) {
    return moment(date, 'YYYY-MM-DD HH:mm');
  }
  return getDefaultRangeValue(value, keyName);
};

const getDefaultPeriodValue = (type: string) => {
  return type === 'event' ? 'tomorrow' : defaultParams.period;
};

export const getModalParams = (
  report: Report,
  isEdit: boolean,
  type: ReportType
): Data => {
  const periodValue =
    isEdit && report.period
      ? report.period.period
      : getDefaultPeriodValue(type);
  return {
    id: isEdit ? report.id : '',
    name: report.name,
    details: getExtraParamValue(report, isEdit, 'details'),
    show_leave: getExtraParamValue(report, isEdit, 'show_leave'),
    include_completed_projects: getExtraParamValue(report, isEdit, 'include_completed_projects'),
    show_self_assigned: getExtraParamValue(
      report,
      isEdit,
      'show_self_assigned'
    ),
    order_by: getExtraParamValue(report, isEdit, 'order_by'),
    time_format: getExtraParamValue(report, isEdit, 'time_format'),

    group_by: getGroupByValue(report, isEdit, type),
    datatype: getDataTypeValue(report, isEdit, type),

    period: periodValue,
    date_from:
      isEdit && report.period
        ? getPeriodDate(report.period.from, periodValue, 'date_from')
        : getDefaultRangeValue(periodValue, 'date_from'),

    time_from:
      isEdit && report.period
        ? getPeriodTime(report.period.from, 'time_from', periodValue)
        : getDefaultRangeValue(periodValue, 'time_from'),

    date_to:
      isEdit && report.period
        ? getPeriodDate(report.period.to, periodValue, 'date_to')
        : getDefaultRangeValue(periodValue, 'date_to'),

    time_to:
      isEdit && report.period
        ? getPeriodTime(report.period.to, 'time_to', periodValue)
        : getDefaultRangeValue(periodValue, 'time_to'),

    sites:
      isEdit && report.filters ? report.filters.site_ids : defaultParams.sites,
    areas:
      isEdit && report.filters
        ? report.filters.area_ids
          ? report.filters.area_ids
          : []
        : defaultParams.areas,
    users:
      isEdit && report.filters ? report.filters.user_ids : defaultParams.users,
    tags:
      isEdit && report.filters ? report.filters.tag_ids : defaultParams.tags,
    roles:
      isEdit && report.filters ? report.filters.role_ids : defaultParams.roles,
    fields:
      isEdit && report.fields ? report.fields : defaultParams.fields,
    project_ids: 
      isEdit && report.filters ? report.filters.projects : defaultParams.project_ids,
    tag_type:
      isEdit && report.filters
        ? report.filters.tag_type
        : getDefaultTagType(isEdit, type),

    show_approved_shifts: getExtraParamValue(
      report,
      isEdit,
      'show_approved_shifts'
    ),
    with_violations: getExtraParamValue(report, isEdit, 'with_violations'),
    show_approved_timesheets: getExtraParamValue(
      report,
      isEdit,
      'show_approved_timesheets'
    ),
    detailed_breaks: getExtraParamValue(report, isEdit, 'detailed_breaks'),
    show: getExtraParamValue(report, isEdit, 'show'),
    date_field_direction: getExtraParamValue(
      report,
      isEdit,
      'date_field_direction'
    ),
    sale_include: getExtraParamValue(report, isEdit, 'sale_include'),
    date_field_start: getExtraParamValue(report, isEdit, 'date_field_start'),
    interval: getExtraParamValue(report, isEdit, 'interval'),
    shifttype: getExtraParamValue(report, isEdit, 'shifttype'),
    date_field_anniversary: getExtraParamValue(
      report,
      isEdit,
      'date_field_anniversary'
    ),
    date_field: getExtraParamValue(report, isEdit, 'date_field'),
    archived: getExtraParamValue(report, isEdit, 'archived'),

    show_inactive_users: getExtraParamValue(
      report,
      isEdit,
      'show_inactive_users'
    ),

    status: getExtraParamValue(report, isEdit, 'status'),
  };
};

const getDate = (
  date: Date,
  time: moment.Moment,
  dateFormat: PreferencesDateFormat
) => {
  const t = moment(time).isValid() ? moment(time).format('HH:mm') : time;
  return `${moment(date, dateFormat).format('YYYY-MM-DD')} ${t}`;
};

const getPeriodParams = (
  data: Data,
  params: any,
  dateFormat: PreferencesDateFormat
) => {
  params.period = {
    period: data.period,
  };
  if (data.date_from && data.date_to) {
    params.period['from'] = getDate(data.date_from, data.time_from, dateFormat);
    params.period['to'] = getDate(data.date_to, data.time_to, dateFormat);
  }
  return params;
};

const getRosterPeriodParams = (
  data: Data,
  params: any,
  dateFormat: PreferencesDateFormat
) => {
  params.period = {
    period: data.period,
  };
  if (data.date_from && data.date_to) {
    params.period['from'] = `${moment(data.date_from, dateFormat).format(
      'YYYY-MM-DD'
    )}`;
    params.period['to'] = `${moment(data.date_to, dateFormat).format(
      'YYYY-MM-DD'
    )}`;
  }
  return params;
};

const getFilters = (data: Data, params: any) => {
  params.filters = {};

  params.filters.user_ids = data.users;
  params.filters.site_ids = data.sites;
  params.filters.area_ids = data.areas;
  params.filters.role_ids = data.roles;
  params.filters.tag_ids = data.tags;
  params.filters.tag_type = data.tag_type;
  params.filters.project_ids = data.projects;
  return params;
};

const getRosterFilters = (data: Data, params: any) => {
  params.filters = {};
  params.filters.site_ids = data.sites;
  params.filters.area_ids = data.areas;
  return params;
};

const getCommonExtraParams = (data: Data, params: any) => {
  params.extra = {
    order_dir: 'asc',
  };
  if (data.group_by && data.group_by !== '') {
    params.extra['group_by'] = data.group_by;
  }
  if (data.order_by && data.order_by !== '') {
    params.extra['order_by'] = data.order_by;
  }
  if (data.time_format) {
    params.extra['time_format'] = data.time_format;
  }
  return params;
};

const getRosterExtraParams = (data: Data, params: any) => {
  params.extra = {
    order_dir: 'asc',
  };
  if (data.group_by && data.group_by !== '') {
    params.extra['group_by'] = data.group_by;
  }
  if (data.order_by && data.order_by !== '') {
    params.extra['order_by'] = data.order_by;
  }
  if (data.time_format) {
    params.extra['time_format'] = data.time_format;
  }
  if (data.show_inactive_users) {
    params.extra.show_inactive_users = data.show_inactive_users;
  }
  return params;
};

const getFields = (data: Data, params: any) => {
  if (data.fields.length > 0) {
    params.fields = data.fields;
  }
  return params;
};

const getGeneralParams = (
  data: Data,
  params: any,
  dateFormat: PreferencesDateFormat
) => {
  params = getPeriodParams(data, params, dateFormat);
  params = getCommonExtraParams(data, params);
  params = getFilters(data, params);
  params = getFields(data, params);
  params.extra.details = data.details;
  params.extra.show_self_assigned = data.show_self_assigned;
  if (data.show_inactive_users) {
    params.extra.show_inactive_users = data.show_inactive_users;
  }
  return params;
};

const getLateParams = (
  data: Data,
  params: any,
  dateFormat: PreferencesDateFormat
) => {
  params = getPeriodParams(data, params, dateFormat);
  params = getCommonExtraParams(data, params);
  params = getFilters(data, params);
  params = getFields(data, params);
  params.extra.details = data.details;
  params.extra.show_approved_timesheets = data.show_approved_timesheets;
  if (data.show_inactive_users) {
    params.extra.show_inactive_users = data.show_inactive_users;
  }
  return params;
};

const getRosterParams = (
  data: Data,
  params: any,
  dateFormat: PreferencesDateFormat
) => {
  params = getRosterPeriodParams(data, params, dateFormat);
  params = getRosterExtraParams(data, params);
  params = getRosterFilters(data, params);
  params = getFields(data, params);
  return params;
};

const getTimesheetParams = (
  data: Data,
  params: any,
  dateFormat: PreferencesDateFormat
) => {
  params = getPeriodParams(data, params, dateFormat);
  params = getCommonExtraParams(data, params);
  params = getFields(data, params);
  params = getFilters(data, params);
  params.extra.details = data.details;
  params.extra.with_violations = data.with_violations;
  params.extra.show_approved_shifts = data.show_approved_shifts; // TODO: remove this field along with the timesheetsReportFilter feature flag
  params.extra.show_inactive_users = data.show_inactive_users;
  params.extra.show_leave = data.show_leave;
  params.extra.include_completed_projects= data.include_completed_projects;
  if (data.status !== 'all') {
    params.extra.status = data.status;
  }
  return params;
};

const getPayrollParams = (
  data: Data,
  params: any,
  dateFormat: PreferencesDateFormat
) => {
  params = getPeriodParams(data, params, dateFormat);
  params = getFields(data, params);
  params = getFilters(data, params);
  return params;
};

const getSaleParams = (
  data: Data,
  params: any,
  dateFormat: PreferencesDateFormat
) => {
  params = getPeriodParams(data, params, dateFormat);
  params = getFields(data, params);
  params = getFilters(data, params);
  params.extra = {
    show: data.show,
    sale_include: data.sale_include,
    time_format: data.time_format,
  };
  if (data.show_inactive_users) {
    params.extra.show_inactive_users = data.show_inactive_users;
  }
  return params;
};

const getEventParams = (
  data: Data,
  params: any,
  dateFormat: PreferencesDateFormat
) => {
  params = getPeriodParams(data, params, dateFormat);
  params = getFields(data, params);
  params = getFilters(data, params);
  if (data.show_inactive_users) {
    params.extra = {
      show_inactive_users: data.show_inactive_users,
    };
  }
  return params;
};

const getCoverageParams = (
  data: Data,
  params: any,
  dateFormat: PreferencesDateFormat
) => {
  params = getPeriodParams(data, params, dateFormat);
  params = getFields(data, params);
  params = getFilters(data, params);
  params.extra = {
    details: data.details,
    show_self_assigned: data.show_self_assigned,
    group_by: data.group_by,
    interval: data.interval,
    datatype: data.datatype,
  };
  if (data.show_inactive_users) {
    params.extra.show_inactive_users = data.show_inactive_users;
  }
  return params;
};

const getTransferParams = (
  data: Data,
  params: any,
  dateFormat: PreferencesDateFormat
) => {
  params = getPeriodParams(data, params, dateFormat);
  params = getFields(data, params);
  params = getFilters(data, params);
  params.extra = {
    // details: data.details,
    // show_self_assigned: data.show_self_assigned,
    time_format: data.time_format,
    datatype: data.datatype,
  };
  return params;
};

const getTagsParams = (
  data: Data,
  params: any,
  dateFormat: PreferencesDateFormat
) => {
  params = getPeriodParams(data, params, dateFormat);
  params = getFields(data, params);
  params = getFilters(data, params);
  params.extra = {
    group_by: data.group_by,
    datatype: data.datatype,
    shifttype: data.shifttype,
  };
  return params;
};

const getUserParams = (
  data: Data,
  params: any,
  dateFormat: PreferencesDateFormat
) => {
  params = getFields(data, params);
  params = getFilters(data, params);
  params.extra = {
    archived: data.archived,
    date_field: data.date_field,
    date_field_direction: data.date_field_direction,
    date_field_start: moment(data.date_field_start).format(dateFormat),
    date_field_anniversary: data.date_field_anniversary,
  };
  if (data.date_field.indexOf('f_') !== -1) {
    params.extra['date_field_is_custom'] = true;
  }
  return params;
};

export const prepareRequestData = (
  data: Data,
  type: ReportType,
  dateFormat: PreferencesDateFormat
) => {
  let params: any = {
    type: type,
  };

  switch (type) {
    case 'timesheet':
      return getTimesheetParams(data, params, dateFormat);
    case 'payroll':
      return getPayrollParams(data, params, dateFormat);
    case 'sale':
      return getSaleParams(data, params, dateFormat);
    case 'event':
      return getEventParams(data, params, dateFormat);
    case 'coverage':
      return getCoverageParams(data, params, dateFormat);
    case 'transfer':
      return getTransferParams(data, params, dateFormat);
    case 'tag':
      return getTagsParams(data, params, dateFormat);
    case 'roster':
      return getRosterParams(data, params, dateFormat);
    case 'late':
      return getLateParams(data, params, dateFormat);
    default:
      return getGeneralParams(data, params, dateFormat);
  }
};

export const getSelectedValues = (
  params: DropDownProps[],
  current: string[]
) => {
  const result: DropDownProps[] = [];
  for (let currentItem of current) {
    for (let param of params) {
      if (param.value === currentItem) {
        result.push(param);
      }
    }
  }
  return result;
};

export const getSelectedGroupedValues = (
  params: SelectPropsOptionGrouped[][],
  current: string[]
) => {
  const result: DropDownProps[] = [];
  for (let currentItem of current) {
    for (let param of params) {
      for (let option of (param as any).options) {
        if (option.value === currentItem) {
          result.push({
            label: `${option.label}`,
            value: option.value,
          });
        }
      }
    }
  }
  return result;
};

export const getFilteredIds = (params: DropDownProps[], current: string[]) => {
  const result: string[] = [];
  if (current) {
    for (let currentItem of current) {
      for (let param of params) {
        if (param.value === currentItem) {
          result.push(param.value);
        }
      }
    }
  }
  return result;
};

export const getFilteredAreaIds = (
  params: SelectPropsOptionGrouped[],
  current: string[]
) => {
  const result: string[] = [];
  for (let currentItem of current) {
    for (let param of params) {
      for (let option of (param as any).options) {
        if (option.value === currentItem) {
          result.push(option.value);
        }
      }
    }
  }
  return result;
};

export const getFilteredUsersBySites = (
  sites: string[],
  users: DropDownProps[]
) => {
  const filteredUsers: DropDownProps[] = [];
  for (let siteId of sites) {
    for (let user of users) {
      const { user_roles } = user;
      for (let role of user_roles) {
        if (role.site_id === siteId) {
          filteredUsers.push(user);
        }
      }
    }
  }
  return _.uniqBy(filteredUsers, 'value');
};

export const getFilteredUsersByAreas = (
  areas: string[],
  users: DropDownProps[]
) => {
  const filteredUsers: DropDownProps[] = [];
  for (let areaId of areas) {
    for (let user of users) {
      const { user_roles } = user;
      for (let role of user_roles) {
        if (role.area_id === areaId) {
          filteredUsers.push(user);
        }
      }
    }
  }
  return _.uniqBy(filteredUsers, 'value');
};

export const getActiveUsersOnly = (usersOptions: DropDownProps[]) => {
  return _.filter(usersOptions, (user: any) => {
    return user.is_active === true;
  });
};

export const getArchivedUsersOnly = (usersOptions: DropDownProps[]) => {
  return _.filter(usersOptions, (user: any) => {
    return user.is_active === false;
  });
};

export const getRoles = (areas: any[]): string[] => {
  let roles: string[] = [];
  if (areas) {
    areas.forEach((area: any) => {
      roles = roles.concat(area.role_ids);
    });
  }
  return roles;
};

export const getAreas = (areas: any[]): string[] => {
  let result: string[] = [];
  if (areas) {
    areas.forEach((area: any) => {
      result.push(area.id);
    });
  }
  return result;
};

export const getSiteRelatedFilters = (sites: string[], areasBySiteId: any) => {
  let rolesIds: string[] = [];
  sites.forEach((siteId: string) => {
    rolesIds = [...rolesIds, ...getRoles(areasBySiteId[siteId])];
  });
  return rolesIds;
};

export const getAreaRelatedFilters = (
  areas: StringMap<AccountTreeArea>,
  area_ids: string[]
) => {
  let rolesIds: string[] = [];
  area_ids.forEach((areaId: string) => {
    const currentArea = areas[areaId];
    rolesIds = [...rolesIds, ...currentArea.role_ids];
  });
  return rolesIds;
};

export const getIdsFromDropDown = (options: DropDownProps[], name = '') => {
  const ids: string[] = [];
  options.forEach((item: DropDownProps) => {
    ids.push(item.value);
  });
  return ids;
};

export const getSelectedValue = (
  params: { value: string; label: string }[],
  current: string
) => {
  for (let param of params) {
    if (param.value === current) {
      return param;
    }
  }
  return [params[0]];
};
