import { Project } from './types';

export const getProjectById = (
  projects: Project[] = [],
  project_id: string | null
) => {
  if (projects.length === 0) {
    return null;
  }
  const project = projects.find((project) => project.id === project_id);
  if (project === undefined) {
    return null;
  } else {
    return project;
  }
};
