import React from 'react';
import { Col, Row, Text, withLayoutAware } from 'elmo-elements';
import { Props } from './type';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  BOX_WHOS_WORKING_CLOCK_OFF_REQUEST,
  BOX_WHOS_WORKING_START_BREAK_REQUEST,
  BOX_WHOS_WORKING_STOP_BREAK_REQUEST,
  getWorkingShiftsSelector,
} from 'state/ManagerDashboard/WhosWorking';
import {
  getPreferenceLabel,
  getTimeFormatted,
  getUserName,
  secondsToHours,
} from 'lib/helpers';
import { DashboardShift, UserFields } from 'type/models';
import { userListSelector } from 'state/UsersCollection';
import { Moment } from 'moment';
import {
  LocalCafeOutlinedIcon,
  RestaurantOutlinedIcon,
  TimerOffOutlinedIcon,
} from 'element';
import Timer from './components/Timer';
import BreakTimer from './components/BreakTimer';
import {
  getDateFormat,
  getLangPreferences,
  getTimeFormat,
} from 'state/Account';
import { getAreas, getRoles, getSites } from 'state/AccountTree';
import { NotesIcon } from 'element/NotesIcon';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Avatar,
} from 'oxygen-elements';
import ProjectsDropdown from '../../../../../../../../element/ProjectsDropdown';

type ActionsCardActionProp = {
  onClick: () => void;
  label: string;
  icon: React.ReactNode;
  id: string;
};

export class WorkingNowCardComponent extends React.Component<Props> {
  render() {
    const { shift, timeFormat } = this.props;
    const userFrom: UserFields = this.props.userList[shift.user_id];

    return (
      <div className="working-now-card" data-testid={shift.timesheet_id}>
        <Card className="who-is-working-card-item" role="row">
          <CardHeader
            className="card-header"
            avatar={
              <Avatar
                src={userFrom.avatar.src}
                alt={getUserName(userFrom)}
                size={'small'}
                light
              />
            }
            title={getUserName(userFrom)}
            titleTypographyProps={{
              fontSize: 16,
              fontWeight: 500,
            }}
          />
          <CardContent role="grid" className="card-content">
            <Row>
              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  {this.positionAreLocation}
                </Text>
                <div data-testid="site-area-role">
                  {this.getLocation(shift)}
                </div>
              </Col>
              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  Start
                </Text>
                <div data-testid="start-time">
                  {getTimeFormatted(
                    timeFormat,
                    shift.timesheet_start as Moment
                  )}
                </div>
              </Col>
              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  Variance
                </Text>
                <div data-testid="variance">{this.renderVariance(shift)}</div>
              </Col>

              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  Hours
                </Text>
                <div data-testid="hours">
                  <Timer date={shift.timesheet_start as Moment} />
                </div>
              </Col>
              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  Project
                </Text>
                <div data-testid="project">
                  {shift.project_name ?? '-'}
                </div>
              </Col>
            </Row>
          </CardContent>
          <CardContent role="grid" className="card-content">
            <Row>
              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  Lunch break
                </Text>
                <div data-testid="meal-break">
                  <BreakTimer shift={shift} paid={false} />
                </div>
              </Col>
              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  Break
                </Text>
                <div data-testid="rest-break">
                  <BreakTimer shift={shift} paid={true} />
                </div>
              </Col>
              <Col span={24 / 4}>
                <NotesIcon notes={shift.notes} customLabel={true} />
              </Col>
              <Col span={24 / 4}>
                <NotesIcon
                  notes={shift.timesheet_notes}
                  id={'timesheet-notes-icon'}
                />
              </Col>
            </Row>
          </CardContent>
          {shift.event_name && (
            <CardContent role="grid" className="card-content">
              <Row>
                <Col span={24 / 4}>
                  <Text size={'xs'} color={'gray'}>
                    Event name:
                  </Text>
                  <div data-testid={'event-name'}>{shift.event_name}</div>
                </Col>
              </Row>
            </CardContent>
          )}
          {this.getActions(shift) && (
            <CardActions className="card-actions">
              {this.getActions(shift)!
                .reverse()
                .map((action: any) => (
                  <Button
                    id={action.id}
                    key={`btn-${action.label}`}
                    size="large"
                    onClick={action.onClick}
                  >
                    {action.icon} {action.label}
                  </Button>
                ))}
            </CardActions>
          )}
        </Card>
      </div>
    );
  }

  getLocation = (roster: DashboardShift) => {
    const { roles, areas, sites } = this.props;
    let response = '';

    if (roster.role_id && roles[roster.role_id]) {
      response = roles[roster.role_id].name;
    }

    if (roster.area_id && areas[roster.area_id]) {
      response += ` - ${areas[roster.area_id].name}`;
    }

    if (roster.site_id && sites[roster.site_id]) {
      response += `, ${sites[roster.site_id].name}`;
    }

    return response;
  };

  get positionAreLocation() {
    const { langPreferences } = this.props;
    const position = getPreferenceLabel(
      langPreferences,
      'role',
      'singular',
      '',
      true
    );
    const area = getPreferenceLabel(
      langPreferences,
      'area',
      'singular',
      '',
      false
    );
    const location = getPreferenceLabel(
      langPreferences,
      'site',
      'singular',
      '',
      false
    );
    return `${position} - ${area}, ${location}`;
  }

  renderVariance = (shift: DashboardShift) => {
    const variance = (shift.variance as number) * 60;

    let varianceView = (
      <>
        {variance > 0 ? '+' : '-'}
        {secondsToHours(Math.abs(variance), false)}{' '}
        {variance > 0 ? 'late' : 'early'}
      </>
    );

    return variance ? varianceView : '-';
  };

  getActions = (shift: DashboardShift): ActionsCardActionProp[] => {
    const timesheetId = shift.timesheet_id as string;

    const actions: ActionsCardActionProp[] = [
      {
        label: 'Clock off',
        id: 'clock-off',
        onClick: () => {
          this.props.clockOff(timesheetId);
        },
        icon: <TimerOffOutlinedIcon />,
      },
    ];

    if (shift.is_on_break) {
      actions.push({
        label: 'Finish break',
        id: 'finish-break',
        onClick: () => {
          this.props.stopBreak(timesheetId);
        },
        icon: <LocalCafeOutlinedIcon />,
      });
    } else {
      actions.push({
        label: 'Break',
        id: 'rest-break',
        onClick: () => {
          this.props.startBreak({ timesheet_id: timesheetId, paid: true });
        },
        icon: <LocalCafeOutlinedIcon />,
      });

      actions.push({
        label: 'Lunch break',
        id: 'meal-break',
        onClick: () => {
          this.props.startBreak({ timesheet_id: timesheetId, paid: false });
        },
        icon: <RestaurantOutlinedIcon />,
      });
    }
    return actions;
  };
}

const mapStateToProps = (state: StoreState) => ({
  workingShifts: getWorkingShiftsSelector(state),
  userList: userListSelector(state),
  areas: getAreas(state),
  roles: getRoles(state),
  sites: getSites(state),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
});

export const WorkingNowCard = connect(mapStateToProps, {
  clockOff: BOX_WHOS_WORKING_CLOCK_OFF_REQUEST,
  startBreak: BOX_WHOS_WORKING_START_BREAK_REQUEST,
  stopBreak: BOX_WHOS_WORKING_STOP_BREAK_REQUEST,
})(withLayoutAware(WorkingNowCardComponent));
