import React, { Component } from 'react';
import {
  ButtonCard,
  Col,
  FormItem,
  LoadingOverlay,
  Paragraph,
  Row,
  Select,
  Text,
} from 'elmo-elements';
import { getSites } from 'state/AccountTree';
import {
  AccountTreeSite,
  FormattedErrors,
  LanguagePreferences,
  PreferencesDateFormat,
  PreferencesTimeFormat,
  TimesheetPayload,
} from 'type/models';
import moment, { Moment } from 'moment';
import {
  convertDecimalToFormattedTime,
  formatStringDurationToMinutes,
  getSelectedAreaRole,
  getSelectedSite,
  getShiftDuration,
  getUserName,
  scrollToErrors,
  updateTimesheetBreaksOnDateChange,
} from 'lib/helpers';

import { connect } from 'react-redux';
import { StoreState } from 'state/types';

import { BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS } from 'state/Roster/RosterShiftModal';
import {
  ListOfAllowedShiftsPayload,
  TimesheetModalProps,
} from 'state/TimesheetModal/types';
import ErrorBox from 'element/ErrorBox';
import {
  BOX_TIMESHEET_SHIFT_MODAL_CLEAR_ERRORS,
  BOX_TIMESHEET_SHIFT_MODAL_GET_LIST_OF_SHIFTS,
  getAllowedShiftsDropDownProps,
  getIsLoadingShifts,
  getTimesheetPayload,
} from 'state/TimesheetModal';
import {
  getDateFormat,
  getLangPreferences,
  getTimeFormat,
} from 'state/Account';
import { capitalize, isNaN } from 'lodash';
import { DropDownOptions } from '../../types';
import { StringMap } from '../../../../../type';
import { isAppMarket, isSiteView } from 'helpers';
import DateTimeGroupImproved from '../../../components/DateTimeGroupImproved';
import { BreakGroup as BreaksImproved } from '../../../components/BreakGroup';
import { NotesFieldExpandable } from '../../../../NotesFieldExpandable';
import { DefaultTimesheetBreaksAlert } from '../../../../DefaultTimesheetBreaksAlert';
import ProjectsDropdown from 'element/ProjectsDropdown';
import { FeatureFlag } from 'element/feature-flags-components';

type OwnProps = {
  openSubModal: (name: string) => void;
  updateCurrentShift: (shift: TimesheetPayload) => void;
  areaRoleOptions: any;
  siteId: string;
  availableSites: DropDownOptions[];
  isEmployee?: boolean;
  user?: {
    prefered_name: string;
    first_name: string;
    last_name: string;
  };
};

type StateProps = {
  shift: TimesheetPayload;
  payload: TimesheetPayload;
  errors: FormattedErrors;
  timesheetModal: TimesheetModalProps;
  allowedShifts: { label: string; value: string }[];
  isLoadingShifts: boolean;
  langPreferences: LanguagePreferences;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
  sites: StringMap<AccountTreeSite>;
};

type DispatchProps = {
  getUsersList: (params: any) => void;
  clearErrors: () => void;
  getListOfShifts: (params: ListOfAllowedShiftsPayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  dateGroup: boolean;
  breakGroup: boolean;
  shift: TimesheetPayload;
  watchErrors: boolean;
};

export class TimesheetEditForm extends Component<Props, State> {
  dateFormat: string = 'YYYY-MM-DD HH:mm:ss';
  modalBound: boolean = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      dateGroup: false,
      breakGroup: false,
      watchErrors: false,
      shift: props.shift,
    };
  }

  render() {
    return this.formContainer();
  }

  errorsMsg = () => {
    return (
      <ErrorBox
        errors={this.props.errors}
        clearErrors={this.props.clearErrors}
        dontWatchGlobalErrors={true}
      />
    );
  };

  formContainer = () => {
    const { id } = this.props.shift;
    const isDisabled = !!(id && id.length > 0);
    const { isLoadingShifts, isEmployee } = this.props;
    return (
      <>
        {this.errorsMsg()}
        <div className={'edit-timesheet-form'}>
          {isSiteView() && (
            <div className="shift-modal-inner spacing-bottom-extra-large spacing-top-extra-large">
              <FormItem label={'Employee'}>
                <ButtonCard
                  id={'choose-user-btn'}
                  className={'user-btn'}
                  title={this.getUserName()}
                  onClick={this.onUserBtnClick}
                  isDisabled={
                    this.props.timesheetModal.isCurrentUser || isDisabled
                  }
                  tabIndex={0}
                />
              </FormItem>
            </div>
          )}
          {isEmployee && (
            <div className="shift-modal-inner spacing-bottom-extra-large spacing-top-extra-large">
              <FormItem label={this.getSitePositionLabel()}>
                <Select
                  id={'sites-dropdown'}
                  name={'available_sites'}
                  options={this.props.availableSites}
                  defaultValue={getSelectedSite(
                    this.props.availableSites,
                    this.state.shift,
                    this.props.siteId
                  )}
                  onChange={this.handleOnChangeSite}
                  value={getSelectedSite(
                    this.props.availableSites,
                    this.state.shift,
                    this.props.siteId
                  )}
                  ariaLabel={this.getSitePositionLabel()}
                />
              </FormItem>
            </div>
          )}
          {!isSiteView() && (
            <div className="shift-modal-inner spacing-bottom-extra-large spacing-top-extra-large">
              <FormItem label={this.getAreaPositionLabel()}>
                <Select
                  id={'area-role-dropdown'}
                  name={'area_role'}
                  options={this.props.areaRoleOptions}
                  defaultValue={getSelectedAreaRole(
                    this.props.areaRoleOptions,
                    this.state.shift
                  )}
                  onChange={this.handleOnChangeAreaRole}
                  value={getSelectedAreaRole(
                    this.props.areaRoleOptions,
                    this.state.shift
                  )}
                  ariaLabel={this.getAreaPositionLabel()}
                />
              </FormItem>
            </div>
          )}
          {isDisabled && (
            <div className="shift-modal-inner spacing-bottom-extra-large">
              <FormItem label={isAppMarket('uk') ? 'Shift' : 'Rostered shift'}>
                <LoadingOverlay isLoading={isLoadingShifts} tabIndex={-1}>
                  <Select
                    id={'available-shifts-dropdown'}
                    name={'related_shift'}
                    value={this.getConnectedShift()}
                    options={this.props.allowedShifts}
                    isClearable={false}
                    onChange={this.updateConnectedShift}
                    isDisabled={this.connectIsDisabled()}
                    placeholder={
                      isLoadingShifts
                        ? 'Searching...'
                        : `${
                            isAppMarket('uk') ? 'Shift' : 'Rostered Shift'
                          } Undetected`
                    }
                    ariaLabel={isAppMarket('uk') ? 'Shift' : 'Rostered shift'}
                  />
                </LoadingOverlay>
              </FormItem>
            </div>
          )}

          <DateTimeGroupImproved
            onChangePeriod={this.onChangePeriod}
            params={this.props.payload as any} // TODO double-check
            siteId={this.props.siteId}
          />

          {this.breaksAreAvailable() ? (
            <BreaksImproved
              breaks={this.props.shift.breaks}
              onChangePicker={this.onChangeBreakPicker}
              onChangeDuration={this.onChangeBreakDuration}
              onChangeCheckbox={this.onChangePaidCheckbox}
              addBreak={this.addBreak}
              timeFormat={this.props.timeFormat}
            />
          ) : (
            <Text>
              {isAppMarket('uk')
                ? 'Add breaks to related shift'
                : 'Add breaks to related Rostered shift'}
            </Text>
          )}

          {!this.props.shift.id && !this.props.shift.breaks.length && (
            <FormItem>
              <DefaultTimesheetBreaksAlert />
            </FormItem>
          )}

          <FeatureFlag name="projects">
            <FeatureFlag.On>
              <div className="shift-modal-inner spacing-bottom-extra-large">
                <FormItem label={'Project'}>
                  <ProjectsDropdown
                    onProjectChange={this.onChangeProject}
                    isEditable={false}
                    isEditing={true}
                    hideLabel={true}
                    defaultValue={this.props.shift.project_id}
                  />
                </FormItem>
              </div>
            </FeatureFlag.On>
          </FeatureFlag>

          <NotesFieldExpandable
            value={this.props.shift.notes}
            setNotesValue={this.setShiftProps}
            tabIndex={0}
          />
          {this.showTotalBreaksInfo()}
        </div>
      </>
    );
  };

  handleOnChangeSelect2 = (e: any[], name: string) => {
    this.setShiftProps(name, this.getIdsFromDropDown(e));
  };

  getIdsFromDropDown = (options: any[]) => {
    const ids: string[] = [];
    options.forEach((item: any) => {
      ids.push(item.value);
    });
    return ids;
  };

  connectIsDisabled = () => {
    const { allowedShifts } = this.props;
    return allowedShifts.length <= 1;
  };

  getConnectedShift = () => {
    const { rostered_shift_id } = this.state.shift;
    const { allowedShifts } = this.props;
    if (rostered_shift_id) {
      for (let allowed of allowedShifts) {
        if (allowed.value === rostered_shift_id) {
          return allowed;
        }
      }
    }
    return allowedShifts[0];
  };

  updateConnectedShift = (e: any) => {
    const { allowedShifts } = this.props;
    if (allowedShifts.length <= 0) {
      return false;
    }
    this.setShiftProps('rostered_shift_id', e.value === '' ? null : e.value);
  };

  showTotalBreaksInfo = () => {
    const { start, end, breaks } = this.state.shift;
    const { siteId } = this.props;
    const total = getShiftDuration(
      start,
      end,
      breaks,
      siteId ? this.props.sites[siteId].timezone_id : undefined
    );
    const details = {
      mealBreak: convertDecimalToFormattedTime(
        total.meal_break.toString(),
        true
      ),
      restBreak: convertDecimalToFormattedTime(
        total.rest_break.toString(),
        true
      ),
      totalHrs: convertDecimalToFormattedTime(total.total_hrs.toString(), true),
    };
    return this.totalBreaksInfoHtml(details);
  };

  totalBreaksInfoHtml = (details: any) => {
    const empty = '0h 0m';
    return (
      <Row className={'mb-5'} role="presentation">
        <Col span={6} role="presentation">
          <Paragraph size={'sm'}>
            {isAppMarket('uk') ? 'Break' : 'Rest break'}
          </Paragraph>
          <Paragraph>
            {details.restBreak !== '' ? details.restBreak : empty}
          </Paragraph>
        </Col>
        <Col span={6} role="presentation">
          <Paragraph size={'sm'}>
            {isAppMarket('uk') ? 'Lunch break' : 'Meal break'}
          </Paragraph>
          <Paragraph>
            {details.mealBreak !== '' ? details.mealBreak : empty}
          </Paragraph>
        </Col>
        <Col span={6} role="presentation">
          <Paragraph size={'sm'}>Total hours</Paragraph>
          <Paragraph>
            {details.totalHrs !== '' ? details.totalHrs : empty}
          </Paragraph>
        </Col>
        <Col span={6} role="presentation" />
      </Row>
    );
  };

  getMaxBreaksNumber = () => {
    const {
      shift: { rostered_shift },
    } = this.props;
    if (rostered_shift !== null) {
      return rostered_shift.breaks.length;
    }
    return 3;
  };

  breaksAreAvailable = () => {
    const { rostered_shift, breaks } = this.props.shift;
    return (
      rostered_shift === null ||
      breaks.length > 0 ||
      rostered_shift.breaks.length > 0
    );
  };

  focusOnBreaks = () => {
    if (this.breaksAreAvailable()) {
      this.expandOptions('breakGroup');
    } else {
      return false;
    }
  };

  addBreak = () => {
    const {
      shift: { breaks, start },
    } = this.state;
    const startDate = moment(start).format('YYYY-MM-DD');
    breaks.push({
      start: moment(`${startDate} 12:00`),
      duration: 0,
      paid: false,
      active: null,
    });
    this.setShiftProps('breaks', breaks);
  };

  onChangePaidCheckbox = (e: any, index: number) => {
    const {
      shift: { breaks },
    } = this.props;
    const {
      target: { checked },
    } = e;
    breaks[index].paid = checked;
    this.setShiftProps('breaks', breaks);
  };

  onChangeBreakPicker = (e: Moment, name: string, index: number) => {
    const {
      shift: { breaks, start },
    } = this.state;
    const time = moment(e).format('HH:mm');
    const startDate = moment(start).format('YYYY-MM-DD');
    breaks[index].start = moment(`${startDate} ${time}`);
    this.setShiftProps('breaks', breaks);
  };

  onChangeBreakDuration = (e: any, name: string, index: number) => {
    const {
      shift: { breaks },
    } = this.props;
    const {
      target: { value },
    } = e;
    const duration = formatStringDurationToMinutes(
      value.toString().replace(/-/i, '')
    );
    breaks[index].duration = isNaN(parseFloat(duration))
      ? 0
      : Math.abs(parseFloat(duration));
    this.setShiftProps('breaks', breaks);
  };

  onChangePeriod = ({ start, end }: { start: Moment; end: Moment }) => {
    const { breaks } = this.state.shift;
    if (end.clone().diff(start.clone(), 'minutes') > 1440) {
      end.subtract(1, 'day');
    }
    this.setState(
      {
        ...this.state,
        shift: {
          ...this.state.shift,
          start: start,
          end: end,
          breaks: updateTimesheetBreaksOnDateChange(start, breaks),
        },
      },
      () => {
        this.props.updateCurrentShift(this.state.shift);
        this.getUsersList();
        this.getShiftsList();
      }
    );
  };

  handleOnChangeAreaRole = (e: any) => {
    const { value } = e;
    const parsed = value.split('__');
    this.setState(
      {
        ...this.state,
        shift: {
          ...this.state.shift,
          area_id: parsed[0],
          role_id: parsed[1],
        },
      },
      () => {
        this.props.updateCurrentShift(this.state.shift);
        this.getShiftsList();
        this.props.clearErrors();
      }
    );
  };

  handleOnChangeSite = (e: any) => {
    const { value } = e;
    this.setState(
      {
        ...this.state,
        shift: {
          ...this.state.shift,
          site_id: value,
        },
      },
      () => {
        this.props.updateCurrentShift(this.state.shift);
        this.props.clearErrors();
      }
    );
  };

  getUsersList = () => {
    const {
      timesheetModal: { isCurrentUser },
    } = this.props;

    if (!isCurrentUser) {
      const { start, end } = this.state.shift;
      this.props.getUsersList({
        start: start,
        end: end,
        site_id: this.props.siteId,
        type: 'timesheets',
      });
    }
  };

  getShiftsList = () => {
    const { id } = this.props.shift;
    const { start, end, area_id, role_id, user_id } = this.state.shift;
    const isEdit = !!(id && id.length > 0);
    if (isEdit && area_id && role_id) {
      this.props.getListOfShifts({
        start: moment(start).format('YYYY-MM-DD HH:mm'),
        end: moment(end!).format('YYYY-MM-DD HH:mm'),
        site_id: this.props.siteId,
        area_id: area_id,
        role_id: role_id,
        user_id: user_id!,
        timesheet_id: id,
      });
    }
  };

  triggerScrollToErrors = () => {
    scrollToErrors(this.props.errors);
    this.watchErrors(false);
  };

  watchErrors = (value: boolean) => {
    this.setState({
      watchErrors: value,
    });
  };

  bindFocusOutOptions = () => {
    const modal = document.querySelector('.edit-timesheet-form');
    if (modal !== null && !this.modalBound) {
      this.modalBound = true;
      modal.addEventListener('click', (e: any) => {
        const t = e.target;
        const isPlaceholder: HTMLElement = t.closest('.group-placeholder');
        const isPicker: HTMLElement =
          t.closest('.elmo-datepicker-calendar') ||
          t.closest('.rc-time-picker-panel');
        const isParentExpanded: HTMLElement = t.closest(
          '.group-options-wrapper.expanded'
        );
        this.closeGroupOptions(isParentExpanded, isPicker, isPlaceholder);
      });
    }
  };

  closeGroupOptions = (
    isParentExpanded: HTMLElement,
    isPicker: HTMLElement,
    isPlaceholder: HTMLElement
  ) => {
    if (
      isParentExpanded === null &&
      isPicker === null &&
      isPlaceholder === null
    ) {
      const { dateGroup, breakGroup } = this.state;
      if (dateGroup === true) {
        this.setState({
          ...this.state,
          dateGroup: false,
        });
      }
      if (breakGroup === true) {
        this.setState({
          ...this.state,
          breakGroup: false,
        });
      }
    }
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const {
      timesheetModal: { isLoading },
    } = prevProps;

    if (isLoading !== this.props.timesheetModal.isLoading) {
      this.setState(
        {
          shift: this.props.shift,
        },
        () => {
          this.getUsersList();
          this.getShiftsList();
        }
      );
    }
    if (prevProps.shift.user_id !== this.props.shift.user_id) {
      this.setState({
        ...this.state,
        shift: {
          ...this.state.shift,
          user_id: this.props.shift.user_id,
        },
      });
    }
    if (
      this.props.errors &&
      this.props.errors.length &&
      this.state.watchErrors
    ) {
      this.triggerScrollToErrors();
    }
    this.bindFocusOutOptions();
  }

  componentDidMount(): void {
    setTimeout(() => {
      this.getUsersList();
      this.getShiftsList();
    }, 1500);
    const usersBtn: null | HTMLButtonElement =
      document.querySelector('#choose-user-btn');
    if (usersBtn) {
      usersBtn.addEventListener('keypress', () => {
        this.props.openSubModal('users');
      });
      usersBtn.focus();
    }
    this.setDefaultPosition();
  }

  setDefaultPosition = () => {
    const { areaRoleOptions, shift } = this.props;
    if (
      areaRoleOptions.length === 2 &&
      !shift.role_id.length &&
      !shift.area_id.length
    ) {
      this.handleOnChangeAreaRole(areaRoleOptions[1]);
    }
  };

  expandOptions = (target: string) => {
    setTimeout(() => {
      if (target === 'dateGroup') {
        this.setState({
          ...this.state,
          dateGroup: true,
          breakGroup: false,
        });
      }
      if (target === 'breakGroup') {
        this.setState({
          ...this.state,
          breakGroup: true,
          dateGroup: false,
        });
      }
    }, 200);
  };

  getUserName = () => {
    const { user_id } = this.props.shift;
    if (user_id !== null) {
      return getUserName(this.props.user!);
    }
    return 'Unassigned';
  };

  getAreaPositionLabel = () => {
    const { langPreferences } = this.props;
    return `${capitalize(langPreferences.area.singular)} - ${
      langPreferences.role.singular
    }`;
  };

  getSitePositionLabel = () => {
    const { langPreferences } = this.props;
    return `${capitalize(langPreferences.site.singular)}`;
  };

  onChangeProject = (projectId: string | null) => {
    if (projectId === '') {
      projectId = null;
    }
    this.setShiftProps('project_id', projectId);
  };

  setShiftProps = (name: string, value: any) => {
    this.setState(
      {
        ...this.state,
        shift: {
          ...this.state.shift,
          [`${name}`]: value,
        },
      },
      () => {
        this.props.updateCurrentShift(this.state.shift);
      }
    );
  };

  onUserBtnClick = () => {
    const {
      openSubModal,
      timesheetModal: { isCurrentUser },
    } = this.props;

    if (!isCurrentUser) {
      openSubModal('users');
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  shift: state.timesheetModal.currentTimesheet,
  payload: getTimesheetPayload(state),
  errors: state.timesheetModal.timesheetModal.errors,
  timesheetModal: state.timesheetModal.timesheetModal,
  allowedShifts: getAllowedShiftsDropDownProps(state),
  isLoadingShifts: getIsLoadingShifts(state),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  sites: getSites(state),
});

const mapToDispatchProps: DispatchProps = {
  getUsersList: BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS,
  clearErrors: BOX_TIMESHEET_SHIFT_MODAL_CLEAR_ERRORS,
  getListOfShifts: BOX_TIMESHEET_SHIFT_MODAL_GET_LIST_OF_SHIFTS,
};

export default connect(mapStateToProps, mapToDispatchProps)(TimesheetEditForm);
