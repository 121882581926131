import React, { Component } from 'react';
import {
  CheckOutlinedIcon,
  CloseOutlinedIcon,
  DefaultTimesheetBreaksAlert,
  ErrorBox,
} from 'element';
import { Col, Row, Text } from 'elmo-elements';
import {
  RosteredShift,
  StringMap,
  Timesheet,
  TimesheetBreak,
  UserFields,
} from 'type';
import moment, { Moment } from 'moment';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  createEmptyTimesheet,
  updateTimesheetBreaksOnDateChange,
} from 'lib/helpers';
import { TimesheetCreateRequest } from 'lib/Api';
import { userListSelector } from 'state/UsersCollection';
import { getFrom, getSiteId } from 'state/TimesheetsCollection';
import {
  BOX_TIMESHEETS_CREATE_REQUEST,
  BOX_TIMESHEETS_ERROR_CLEARED,
  BOX_TIMESHEETS_HIDE_FORM,
  getNewShift,
} from 'state/Timesheets/Timesheets';
import { NewShift } from 'state/Timesheets/Timesheets/types';
import { getEndDate } from 'state/Timesheets/Timesheets/helpers';
import ShiftUser from '../ShiftCard/components/ShiftUser';
import ShiftLocation from '../ShiftCard/components/ShiftLocation';
import ShiftTime from '../ShiftCard/components/ShiftTime';
import Breaks from '../ShiftCard/components/Breaks';
import { TimesheetNotesField } from '../TimesheetNotesField';
import RosteredShiftsSelector from './components/RosteredShiftsSelector';
import { Card, CardActions, CardContent } from '@mui/material';
import { Button, Avatar } from 'oxygen-elements';
import ProjectsDropdown from 'element/ProjectsDropdown';
import { FeatureFlag } from 'element/feature-flags-components';

type StateProps = {
  userList: StringMap<UserFields>;
  siteId: string;
  fromDate: Moment;
  newShift: NewShift;
};

type DispatchProps = {
  hideForm: () => void;
  createTimesheet: (data: TimesheetCreateRequest) => void;
  clearErrors: (id: string) => void;
};

type Props = StateProps & DispatchProps;

type State = {
  timesheet: Timesheet;
  isEditing: boolean;
  changes: number;
  isStart: boolean;
  isEnd: boolean;
};

export class NewShiftCardComponent extends Component<Props, State> {
  private colProps = {
    xs: 12,
    className: 'mb-1 mb-xs-2 mb-md-4',
  };

  state = {
    changes: 0,
    timesheet: {
      ...createEmptyTimesheet(),
      start: this.props.fromDate,
      end: this.props.fromDate.clone(),
    },
    isStart: false,
    isEnd: false,
    isEditing: false,
  };

  componentWillUnmount() {
    this.props.hideForm();
    this.props.clearErrors(this.state.timesheet.id);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.fromDate !== prevProps.fromDate) {
      this.setState({
        timesheet: {
          ...this.state.timesheet,
          start: this.props.fromDate,
          end: this.props.fromDate.clone(),
        },
      });
    }
  }

  render() {
    const { timesheet } = this.state;
    const { newShift } = this.props;
    const userFrom: UserFields = this.props.userList[timesheet.user_id];
    return (
      <Card className="action-shift-card" id="new-shift-card">
        <CardContent>
          {newShift.errors && (
            <ErrorBox
              dontWatchGlobalErrors={true}
              errors={newShift.errors}
              clearErrors={() => {
                this.props.clearErrors(timesheet.id);
              }}
            />
          )}
          {!timesheet.breaks.length && (
            <Col span={24} className={'mb-4 mt-4'}>
              <div className={''}>
                <DefaultTimesheetBreaksAlert />
              </div>
            </Col>
          )}
        </CardContent>
        <CardContent className="action-shift-card__content">
          <Row>
            <Col {...this.colProps} sm={6}>
              <Text size={'xs'} color={'gray'} id={'select-user'}>
                &nbsp;
              </Text>
              <div className={'absolute-icon'}>
                {userFrom && (
                  <Avatar
                    alt={userFrom.avatar.label}
                    src={userFrom.avatar.src}
                    light
                  />
                )}
              </div>
              <ShiftUser
                isEditable={true}
                user_id={timesheet.user_id}
                onUserChange={this.onUserChangeHandler}
              />
            </Col>

            <Col {...this.colProps} sm={10}>
              <RosteredShiftsSelector
                userId={timesheet.user_id}
                onChange={this.onChangeRosteredShiftHandler}
                isEditing={this.state.isEditing}
              />
            </Col>
            <FeatureFlag name="projects">
              <FeatureFlag.On>
                <Col span={6}>
                  <ProjectsDropdown
                    onProjectChange={this.onChangeProject}
                    isEditable={false}
                    isEditing={true}
                  />
                </Col>
              </FeatureFlag.On>
            </FeatureFlag>
          </Row>
          <Row>
            <Col {...this.colProps} sm={6}>
              <ShiftLocation
                timesheet={timesheet}
                onChange={this.handleOnChangeAreaRole}
                isEditable={true}
                isDisabled={!timesheet.user_id}
                isEditing={true}
              />
            </Col>
            <Col {...this.colProps} sm={5}>
              <ShiftTime
                displayDate={true}
                isEditable={true}
                isEditing={true}
                label={'Start'}
                time={timesheet.start}
                onChange={this.onStartTimeChangeHandler}
                isEmpty={!this.state.isStart}
              />
            </Col>
            <Col {...this.colProps} sm={5}>
              <ShiftTime
                key={this.state.changes}
                displayDate={true}
                isEditable={true}
                isEditing={true}
                label={'End'}
                time={timesheet.end}
                onChange={this.onEndTimeChangeHandler}
                isEmpty={!this.state.isEnd}
              />
            </Col>
            <Col {...this.colProps} sm={8}>
              <Breaks
                onChangeDate={this.onChangeDate}
                onChangeDuration={this.onChangeDuration}
                onChangePaid={this.onChangePaid}
                onClear={this.onClear}
                breaks={timesheet.breaks}
                isEditable={true}
                isEditing={true}
                maxBreaks={5}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <TimesheetNotesField
                value={timesheet.notes}
                setNotesValue={this.onChangeNotes}
                isEditing={true}
                onClick={() => false}
              />
            </Col>
          </Row>
        </CardContent>
        {this.actions && (
          <CardActions className="action-shift-card__actions">
            {this.actions.reverse().map((action: any) => (
              <Button
                key={action.label}
                size="large"
                onClick={action.onClick}
                disabled={action.isDisabled}
                loading={action.isLoading}
              >
                {action.icon} {action.label}
              </Button>
            ))}
          </CardActions>
        )}
      </Card>
    );
  }

  onChangeNotes = (value: string | null) => {
    this.setState({
      ...this.state,
      timesheet: {
        ...this.state.timesheet,
        notes: value,
      },
      isEditing: true,
    });
  };

  onChangeRosteredShiftHandler = (shift: RosteredShift | null) => {
    if (shift) {
      this.setTimesheetData(shift);
    } else {
      if (!this.state.isEditing) {
        this.resetTimesheetData();
      }
    }
  };

  setTimesheetData = (rosteredShift: RosteredShift) => {
    this.setState({
      timesheet: {
        ...this.state.timesheet,
        start: rosteredShift.start,
        end: rosteredShift.end,
        breaks: [...(rosteredShift.breaks as TimesheetBreak[])],
        area_id: rosteredShift.area_id,
        role_id: rosteredShift.role_id,
      },
      isStart: true,
      isEnd: true,
      isEditing: false,
    });
  };

  resetTimesheetData = () => {
    this.setState({
      timesheet: {
        ...createEmptyTimesheet(),
        user_id: this.state.timesheet.user_id,
        start: this.props.fromDate,
        end: this.props.fromDate.clone(),
      },
      isStart: false,
      isEnd: false,
      isEditing: false,
    });
  };

  onStartTimeChangeHandler = (start: Moment) => {
    const {
      timesheet: { end, breaks },
    } = this.state;
    if (end) {
      this.setState({
        ...this.state,
        timesheet: {
          ...this.state.timesheet,
          start,
          end: this.state.isEnd
            ? getEndDate(start, end)
            : this.state.timesheet.end,
          breaks: updateTimesheetBreaksOnDateChange(start, breaks),
        },
        isStart: true,
        isEditing: true,
      });
    }
  };

  onEndTimeChangeHandler = (end: Moment) => {
    const {
      timesheet: { start },
    } = this.state;
    this.setState({
      ...this.state,
      timesheet: {
        ...this.state.timesheet,
        end: getEndDate(start, end),
      },
      isEnd: true,
      isEditing: true,
    });
  };

  onUserChangeHandler = (id: string) => {
    this.setState({
      timesheet: {
        ...this.state.timesheet,
        user_id: id,
        role_id: '',
        area_id: '',
      },
    });
  };

  onChangeDate = (e: Moment, index: number) => {
    const {
      timesheet: { breaks, start },
    } = this.state;
    const time = moment(e).format('HH:mm');
    const startDate = moment(start).format('YYYY-MM-DD');

    if (!breaks[index]) {
      breaks[index] = this.emptyBreak;
    }
    breaks[index].start = moment(`${startDate} ${time}`);

    this.setState({
      timesheet: {
        ...this.state.timesheet,
        breaks: updateTimesheetBreaksOnDateChange(start, breaks),
      },
      isEditing: true,
    });
  };

  onChangeDuration = (duration: number, index: number) => {
    const {
      timesheet: { breaks },
    } = this.state;
    if (!breaks[index]) {
      breaks[index] = this.emptyBreak;
    }
    breaks[index].duration = duration;
    this.setState({
      timesheet: {
        ...this.state.timesheet,
        breaks: breaks,
      },
      isEditing: true,
    });
  };

  onChangePaid = (paid: boolean, index: number) => {
    const {
      timesheet: { breaks },
    } = this.state;
    if (!breaks[index]) {
      breaks[index] = this.emptyBreak;
    }
    breaks[index].paid = paid;
    this.setState({
      timesheet: {
        ...this.state.timesheet,
        breaks: breaks,
      },
      isEditing: true,
    });
  };

  onClear = (index: number) => {
    let breaks = this.state.timesheet.breaks;
    delete breaks[index];
    this.setState({
      timesheet: {
        ...this.state.timesheet,
        breaks: breaks,
      },
      isEditing: true,
    });
  };

  handleOnChangeAreaRole = (e: { value: string; label: string }) => {
    const { value } = e;
    const parsed = value.split('__');
    this.setState({
      ...this.state,
      timesheet: {
        ...this.state.timesheet,
        area_id: parsed[0],
        role_id: parsed[1],
      },
      isEditing: true,
    });
  };

  onChangeProject = (projectId: string) => {
    this.setState({
      ...this.state,
      timesheet: {
        ...this.state.timesheet,
        project_id: projectId,
      },
    });
  };

  get emptyBreak() {
    const {
      timesheet: { start },
    } = this.state;
    return {
      start: start.clone().set({ hours: 12, minutes: 0 }),
      duration: 0,
      paid: false,
      active: false,
    };
  }

  get actions() {
    const { newShift } = this.props;
    return [
      {
        label: 'Cancel',
        id: 'cancel',
        onClick: () => {
          this.props.hideForm();
        },
        icon: <CloseOutlinedIcon />,
        isDisabled: false,
      },
      {
        label: 'Save',
        id: 'save',
        onClick: this.onClickSaveHandler,
        icon: <CheckOutlinedIcon />,
        isDisabled: !this.canSave,
        isLoading: newShift.isLoading,
      },
    ];
  }

  onClickSaveHandler = () => {
    this.props.createTimesheet({
      ...this.payload,
      site_id: this.props.siteId,
    });
  };

  get canSave() {
    const { timesheet, isStart, isEnd } = this.state;
    return !!timesheet.user_id && !!timesheet.area_id && isStart && isEnd;
  }

  get payload(): TimesheetCreateRequest {
    const {
      user_id,
      area_id,
      role_id,
      site_id,
      breaks,
      start,
      end,
      notes,
      project_id,
    } = this.state.timesheet;
    return {
      user_id,
      area_id,
      role_id,
      site_id,
      breaks: breaks.filter((b) => b !== null),
      start,
      end: end as Moment,
      notes,
      project_id,
    };
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  userList: userListSelector(state),
  siteId: getSiteId(state),
  fromDate: getFrom(state),
  newShift: getNewShift(state),
});

const mapDispatchToProps: DispatchProps = {
  hideForm: BOX_TIMESHEETS_HIDE_FORM,
  createTimesheet: BOX_TIMESHEETS_CREATE_REQUEST,
  clearErrors: BOX_TIMESHEETS_ERROR_CLEARED,
};

export const NewShiftCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewShiftCardComponent);
