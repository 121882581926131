import React, { Component } from 'react';
import { Col, Heading, Paragraph, Row } from 'elmo-elements';
import {
  AccountTreeArea,
  AccountTreeRole,
  FormattedErrors,
  LanguagePreferences,
  PreferencesDateFormat,
  PreferencesTimeFormat,
  ServerUserFields,
  ShiftBreak,
  StringMap,
  TimesheetBreak,
  TimesheetPayload,
} from 'type';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import {
  convertDecimalToFormattedTime,
  getAreaPositionLabel,
  getBreaksInfo,
  getDateTimePlaceholder,
  getLinkedRoleLabel,
  getShiftDuration,
  getUserName,
} from 'lib/helpers';
import {
  getDateFormat,
  getLangPreferences,
  getTimeFormat,
  timeFormatSelector,
  unlinkedRoleLabelSelector,
} from 'state/Account';
import {
  BOX_TIMESHEET_SHIFT_MODAL_CLEAR_ERRORS,
  getCurrentTimesheet,
  getTimesheetModalErrors,
} from 'state/TimesheetModal';
import { getUserListResponse } from 'state/UsersCollection';
import { DSTIcon } from 'element/DSTIcon';
import { getAccountTree } from 'state/AccountTree';
import { isAppMarket } from '../../../../../helpers';
import { TimesheetInfoMap } from './TimesheetInfoMap';
import { Avatar } from 'oxygen-elements';
import { getProjectById } from 'state/Projects/helpers';
import { getProjects } from 'state/Projects/selectors';
import { Project } from 'state/Projects/types';
import { FeatureFlag } from 'element/feature-flags-components';

type OwnProps = {};

type StateProps = {
  shift: TimesheetPayload;
  users: StringMap<ServerUserFields>;
  areas: StringMap<AccountTreeArea>;
  roles: StringMap<AccountTreeRole>;
  errors: FormattedErrors;
  langPreferences: LanguagePreferences;
  dateFormat: PreferencesDateFormat;
  timeFormat: string;
  tFormat: PreferencesTimeFormat;
  unlinkedRoleLabel: string;
  projects: Project[];
};

type DispatchProps = {
  clearErrors: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const emptyPlaceholder = '---';

export class TimesheetInfo extends Component<Props> {
  render() {
    const { langPreferences } = this.props;
    const { start, end, breaks, timezone_id } = this.props.shift;
    return (
      <>
        <Row className={'timesheet-info-block'} role="presentation">
          <Col isAuto={true} span={12} role="presentation">
            <Paragraph size={'sm'}>User</Paragraph>

            <div className={'timesheet-info-block__avatar-box'}>
              <Avatar
                size={'small'}
                alt={this.userName}
                src={this.avatarUrl}
                light
              />
              <span className={'username-text'}>{this.userName}</span>
            </div>
          </Col>

          <Col isAuto={true} span={12} role="presentation">
            <Paragraph size={'sm'}>
              {getAreaPositionLabel(langPreferences)}
            </Paragraph>

            <Paragraph>{this.areaRoleLabel}</Paragraph>
          </Col>
        </Row>

        <Row className={'timesheet-date-breaks-info'} role="presentation">
          <Col isAuto={true} span={12} role="presentation">
            <Paragraph size={'sm'}>Date and time</Paragraph>

            <div>
              {this.dateAndTime}
              <DSTIcon
                isDST={this.props.shift.is_dst_intersect}
                offsetLeft={4}
              />
            </div>
          </Col>

          <Col isAuto={true} span={12} role="presentation">
            <Paragraph size={'sm'}>Break</Paragraph>

            <Paragraph>
              {getBreaksInfo(this.props.tFormat, breaks, emptyPlaceholder)}
            </Paragraph>
          </Col>
        </Row>

        <FeatureFlag name="projects">
          <FeatureFlag.On>
            <Row className={'timesheet-project-info'} role="presentation">
              <Col isAuto={true} span={6} role="presentation">
                <Paragraph size={'sm'}>Project</Paragraph>
                <Paragraph className={'break-word'}>{this.project}</Paragraph>
              </Col>
            </Row>
          </FeatureFlag.On>
        </FeatureFlag>

        <Row className={'timesheet-info-notes'}>
          <Col isAuto={true} span={12} role="presentation">
            <Paragraph size={'sm'}>Notes</Paragraph>
            <Paragraph className={'break-word'}>{this.notes}</Paragraph>
          </Col>
        </Row>

        <TimesheetInfoMap />

        {this.showTotalBreaksInfo(start, end, breaks, timezone_id!)}

        {this.showRosteredShiftInfo}

        {this.showVariance}
      </>
    );
  }

  private get project(): string {
    const { shift, projects } = this.props;
    const project = getProjectById(projects, shift.project_id);
    return project ? project.name : emptyPlaceholder;
  }

  private get notes(): string {
    const { shift } = this.props;

    if (!shift.notes) {
      return emptyPlaceholder;
    }

    return shift.notes;
  }

  private get dateAndTime() {
    const {
      shift: { start, end },
      dateFormat,
      timeFormat,
    } = this.props;

    return getDateTimePlaceholder({
      start,
      end,
      dateFormat,
      timeFormat,
      hideEndDate: true,
    });
  }

  private get userName() {
    const { user_id } = this.props.shift;
    return user_id ? getUserName(this.props.users[user_id]) : 'Unassigned';
  }

  private get avatarUrl() {
    const { user_id } = this.props.shift;
    if (user_id) {
      const user = this.props.users[user_id];
      if (user && user.avatar_url) {
        return user.avatar_url;
      }
    }
    return '';
  }

  private get areaRoleLabel() {
    const {
      shift: { role_id, area_id },
      areas,
      roles,
      unlinkedRoleLabel,
    } = this.props;

    const roleLabel = getLinkedRoleLabel({
      areas,
      roles,
      area_id,
      role_id,
      fallBack: unlinkedRoleLabel,
    });

    return areas[area_id] ? `${areas[area_id].name} - ${roleLabel}` : '-';
  }

  private get showRosteredShiftInfo() {
    const { shift } = this.props;
    const { rostered_shift_id, rostered_shift } = shift;
    if (rostered_shift_id === null || rostered_shift === null) {
      return null;
    }
    return (
      <>
        <Heading isBold={true}>
          {isAppMarket('uk') ? 'Shift' : 'Rostered shift'}
        </Heading>
        <Row
          className={'mt-2 mb-3 rostered-date-breaks-info'}
          role="presentation"
        >
          <Col isAuto={true} span={12} role="presentation">
            <Paragraph size={'sm'}>Date and time</Paragraph>
            <div className={'data-time-info'}>
              {this.rosteredShiftDateTime}
              <DSTIcon isDST={rostered_shift.is_dst_intersect} offsetLeft={2} />
            </div>
          </Col>
          <Col isAuto={true} span={12} role="presentation">
            <Paragraph size={'sm'}>Break</Paragraph>
            <Paragraph className={'breaks-info'}>
              {getBreaksInfo(
                this.props.tFormat,
                rostered_shift.breaks,
                emptyPlaceholder
              )}
            </Paragraph>
          </Col>
        </Row>
        {this.showTotalBreaksInfo(
          rostered_shift.start,
          rostered_shift.end,
          rostered_shift.breaks,
          rostered_shift.timezone_id!
        )}
      </>
    );
  }

  private get rosteredShiftDateTime(): string {
    const {
      dateFormat,
      timeFormat,
      shift: { rostered_shift },
    } = this.props;

    if (rostered_shift) {
      const { start, end } = rostered_shift;

      return getDateTimePlaceholder({
        start: moment.parseZone(start),
        end: moment.parseZone(end),
        dateFormat,
        timeFormat,
        hideEndDate: true,
      });
    }

    return '';
  }

  private showTotalBreaksInfo = (
    start: Moment,
    end: Moment | null,
    breaks: ShiftBreak[] | TimesheetBreak[],
    timezoneId: string
  ) => {
    const total = getShiftDuration(start, end, breaks, timezoneId);
    const details = {
      mealBreak: convertDecimalToFormattedTime(
        total.meal_break.toString(),
        true
      ),
      restBreak: convertDecimalToFormattedTime(
        total.rest_break.toString(),
        true
      ),
      totalHrs: convertDecimalToFormattedTime(total.total_hrs.toString(), true),
    };
    return this.totalBreaksInfoHtml(details);
  };

  private totalBreaksInfoHtml = (details: any) => {
    const empty = '0h 0m';
    return (
      <Row className={'mb-5 total-breaks-info'} role="presentation">
        <Col span={6} role="presentation">
          <Paragraph size={'sm'}>
            {isAppMarket('uk') ? 'Break' : 'Rest break'}
          </Paragraph>
          <Paragraph>
            {details.restBreak !== '' ? details.restBreak : empty}
          </Paragraph>
        </Col>
        <Col span={6} role="presentation">
          <Paragraph size={'sm'}>
            {isAppMarket('uk') ? 'Lunch break' : 'Meal break'}
          </Paragraph>
          <Paragraph>
            {details.mealBreak !== '' ? details.mealBreak : empty}
          </Paragraph>
        </Col>
        <Col span={6} role="presentation">
          <Paragraph size={'sm'}>Total hours</Paragraph>
          <Paragraph>
            {details.totalHrs !== '' ? details.totalHrs : empty}
          </Paragraph>
        </Col>
        <Col span={6} role="presentation" />
      </Row>
    );
  };

  private getSign = (result: number) => {
    if (result === 0) {
      return '';
    }
    return result < 0 ? '-' : '+';
  };

  private get showVariance() {
    const {
      shift: { rostered_shift },
      shift,
    } = this.props;
    if (rostered_shift !== null) {
      const shiftTime = getShiftDuration(
        shift.start,
        shift.end,
        shift.breaks,
        rostered_shift.timezone_id
      );
      const rosterTime = getShiftDuration(
        rostered_shift.start,
        rostered_shift.end,
        rostered_shift.breaks,
        rostered_shift.timezone_id
      );
      const r = shiftTime.meal_break - rosterTime.meal_break;
      const p = shiftTime.rest_break - rosterTime.rest_break;
      const t = shiftTime.total_hrs - rosterTime.total_hrs;
      const difference = {
        mealBreak:
          this.getSign(r) +
          convertDecimalToFormattedTime(Math.abs(r).toString(), true),
        restBreak:
          this.getSign(p) +
          convertDecimalToFormattedTime(Math.abs(p).toString(), true),
        totalHrs:
          this.getSign(t) +
          convertDecimalToFormattedTime(Math.abs(t).toString(), true),
      };
      return (
        <>
          <Heading isBold={true}>Variance</Heading>
          {this.totalBreaksInfoHtml(difference)}
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: StoreState): StateProps => {
  const { areas, roles } = getAccountTree(state);

  return {
    shift: getCurrentTimesheet(state),
    users: getUserListResponse(state),
    areas,
    roles,
    timeFormat: timeFormatSelector(state),
    errors: getTimesheetModalErrors(state),
    langPreferences: getLangPreferences(state),
    dateFormat: getDateFormat(state),
    tFormat: getTimeFormat(state),
    unlinkedRoleLabel: unlinkedRoleLabelSelector(state),
    projects: getProjects(state),
  };
};

const mapToDispatchProps: DispatchProps = {
  clearErrors: BOX_TIMESHEET_SHIFT_MODAL_CLEAR_ERRORS,
};

export default connect(mapStateToProps, mapToDispatchProps)(TimesheetInfo);
