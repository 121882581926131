import React, { Component } from 'react';
import { Loader } from 'elmo-elements';
import TimesheetEditForm from './components/TimesheetEditForm';
import { CheckIcon, CloseIcon } from 'element/icons';
import '../ShiftModals.scss';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  BOX_TIMESHEET_SHIFT_MODAL_APPROVE_TIMESHEET,
  BOX_TIMESHEET_SHIFT_MODAL_CLEAR_ERRORS,
  BOX_TIMESHEET_SHIFT_MODAL_CLOSE,
  BOX_TIMESHEET_SHIFT_MODAL_DELETE_ALLOWANCE,
  BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT,
  BOX_TIMESHEET_SHIFT_MODAL_RESTORE_AWARDS,
  BOX_TIMESHEET_SHIFT_MODAL_SET_PAYLOAD_PROPS,
  BOX_TIMESHEET_SHIFT_MODAL_SET_SHIFT_USER,
  BOX_TIMESHEET_SHIFT_MODAL_TOGGLE_APPROVE,
  BOX_TIMESHEET_SHIFT_MODAL_UPDATE,
  canSaveAward,
  getCurrentTimesheet,
  getTimesheetModal,
  getTimesheetPayload,
} from 'state/TimesheetModal';
import {
  ServerUserCurrent,
  ServerUserFields,
  TimesheetPayload,
} from '../../../type';
import TimesheetOverlapConfirmationModal from '../TimesheetOverlapConfirmationModal';
import TimesheetInfo from './components/TimesheetInfo';
import ModalActions from './components/ModalActions';
import { UserModal } from '../components';
import ApproveModal from './components/ApproveModal';
import moment from 'moment';
import { DispatchProps, DropDownOptions, Props, StateProps } from './types';
import {
  getAccountTree,
  activeAreasBySiteIdSelector,
} from '../../../state/AccountTree';
import {
  getOptionsForCurrentUser,
  getOptionsForUnassignedRoster,
  getSelectedAreaRole,
  getTimesheetOptionsForCurrentUser,
  getUserName,
  updateDatesOfBreaksForMidnightShifts,
} from 'lib/helpers';
import ErrorBox from 'element/ErrorBox';
import { getCurrentUser, hasPermissionSelector } from 'state/Auth';
import { getAutoApproveTimesheets } from 'state/Account';
import { doesTimesheetNeedApproval } from './helpers';
import { getUserListResponse } from 'state/UsersCollection';
import _ from 'lodash';
import { isSiteView } from '../../../helpers';
import { scrollLock } from 'elmo-elements/_lib/helper';
import {
  PageDialog,
  PageDialogBackIconButton,
  PageDialogCancel,
  PageDialogCloseIconButton,
  PageDialogSubmit,
} from 'element';
import { DialogActions, DialogTitle } from 'oxygen-elements';
import { DialogContent, Button } from 'extended-oxygen-elements';

type State = {
  activeSubModal: string;
  isValid: boolean;
  showApproveModal: boolean;
};

export class TimesheetModal extends Component<Props, State> {
  state = {
    activeSubModal: '',
    isValid: false,
    showApproveModal: false,
  };

  render() {
    const { isOpened } = this.props.timesheetModal;

    return isOpened ? (
      <>
        {this.mainModal()}
        {this.subModals()}

        <TimesheetOverlapConfirmationModal
          confirmOverlap={this.submitOverlappedShift}
          confirmApproveOverlap={this.submitOverlappedApprove}
          confirmDeletion={this.makeHandleDeleteShift(true)}
        />
        <ApproveModal
          isOpened={this.state.showApproveModal}
          approveAction={this.approveAction}
          closeModal={this.closeApproveModal}
        />
      </>
    ) : null;
  }

  closeApproveModal = () => {
    this.setState({
      ...this.state,
      showApproveModal: false,
    });
  };

  approveAction = (value: boolean) => {
    this.props.updateApproveProperty(value);
    this.setState(
      {
        ...this.state,
        showApproveModal: false,
      },
      () => {
        this.props.updateTimesheet(this.getPayload());
      }
    );
  };

  submitOverlappedShift = () => {
    const { payload } = this.props;
    payload.id === ''
      ? this.props.createTimesheet(this.getPayload(true))
      : this.props.updateTimesheet(this.getPayload(true));
  };

  mainModal = () => {
    const { timesheet } = this.props;
    return timesheet.id === '' ? this.getCreateModal() : this.getEditModal();
  };

  getModalTitle = () => {
    const { isLoading, id } = this.props.timesheet as any; // TODO double-check
    const title = id ? 'Edit Timesheet' : 'Timesheet';
    return !isLoading ? (
      <div className="long-text">{`${title} ${this.getUserNameOrRoleName()}`}</div>
    ) : (
      '<div className="long-text">Receiving data...</div>'
    );
  };

  getUserNameOrRoleName = () => {
    const { users, roles, currentUser, isEmployee } = this.props;
    const { user_id, role_id } = this.props.timesheet;
    if (isSiteView() && role_id) {
      return `- ${roles[role_id].name}`;
    }
    if (isEmployee) {
      return `- ${getUserName(currentUser)}`;
    }
    if (user_id) {
      return `- ${getUserName(users[user_id])}`;
    }
    return '';
  };

  getEditModal = () => {
    const { isOpened, isLoading } = this.props.timesheetModal;
    return (
      <PageDialog
        id={'timesheet-modal'}
        maxWidth={'sm'}
        open={isOpened}
        className={'shift-modal'}
      >
        <DialogActions
          className={'shift-modal-header'}
          sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3, padding: 0 }}
        >
          <DialogTitle>
            {this.getModalTitle()}{' '}
            <PageDialogCloseIconButton onClose={this.props.closeModal} />
          </DialogTitle>
        </DialogActions>
        <DialogContent className={'shift-modal-content'}>
          {isLoading ? <Loader type="spinner" /> : this.modalEditContent()}
        </DialogContent>
        {!isLoading && (
          <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
            <PageDialogCancel
              onClick={this.props.closeModal}
              fullWidth={false}
              size={'large'}
            >
              Cancel
            </PageDialogCancel>
            {this.getEditButton()}
          </DialogActions>
        )}
      </PageDialog>
    );
  };

  getCreateModal = () => {
    const { isOpened, isLoading } = this.props.timesheetModal;
    return (
      <PageDialog
        id={'timesheet-modal-create'}
        maxWidth={'sm'}
        open={isOpened}
        className={'shift-modal'}
      >
        <DialogActions
          className={'shift-modal-header'}
          sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3, padding: 0 }}
        >
          <DialogTitle>
            {this.getModalTitle()}{' '}
            <PageDialogCloseIconButton onClose={this.props.closeModal} />
          </DialogTitle>
        </DialogActions>
        <DialogContent className={'shift-modal-content'}>
          {isLoading ? <Loader type="spinner" /> : this.timesheetForm()}
        </DialogContent>
        {!isLoading && (
          <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
            <PageDialogCancel
              onClick={this.props.closeModal}
              fullWidth={false}
              size={'large'}
            >
              Cancel
            </PageDialogCancel>
            {this.getButton()}
          </DialogActions>
        )}
      </PageDialog>
    );
  };

  getEditSubModal = () => {
    const {
      timesheet,
      timesheetModal: { isLoading },
    } = this.props;
    return (
      <PageDialog
        id={'timesheet-modal-edit'}
        maxWidth={'sm'}
        open={this.state.activeSubModal === 'edit'}
        className={'shift-modal'}
      >
        <DialogActions
          className={'shift-modal-header'}
          sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3, padding: 0 }}
        >
          <DialogTitle>
            <PageDialogBackIconButton onClose={this.closeSubModal} />
            {this.getModalTitle()}{' '}
          </DialogTitle>
        </DialogActions>
        <DialogContent className={'shift-modal-content'}>
          {timesheet.id !== '' && this.timesheetForm()}
        </DialogContent>
        {!isLoading && (
          <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
            <PageDialogCancel
              onClick={this.closeSubModal}
              fullWidth={false}
              size={'large'}
            >
              Cancel
            </PageDialogCancel>
            {this.getButton()}
          </DialogActions>
        )}
      </PageDialog>
    );
  };

  getButton = () => {
    const { isUpdating } = this.props.timesheetModal;
    const { isValid } = this.state;
    return (
      <PageDialogSubmit
        onClick={this.submitModal}
        loading={isUpdating}
        disabled={isUpdating || !isValid}
        fullWidth={false}
        tabIndex={0}
        size={'large'}
      >
        Save
      </PageDialogSubmit>
    );
  };

  getEditButton = () => {
    const { isUpdating } = this.props.timesheetModal;
    const { timesheet, canApprove } = this.props;
    if (!canApprove) {
      return null;
    }
    return (
      <Button
        id={'submit-timesheet-btn'}
        onClick={this.approveTimesheet}
        loading={isUpdating}
        disabled={isUpdating}
        variant={'outlined'}
        tabIndex={0}
      >
        {timesheet.is_approved ? <CloseIcon /> : <CheckIcon />}{' '}
        {timesheet.is_approved ? 'Unapprove' : 'Approve'}
      </Button>
    );
  };

  modalEditContent = () => {
    return (
      <>
        <ErrorBox
          errors={this.props.errors}
          clearErrors={this.props.clearErrors}
          dontWatchGlobalErrors={true}
        />
        <ModalActions
          openSubModal={this.openSubModal}
          deleteShift={this.makeHandleDeleteShift(false)}
          isUpdating={this.props.timesheetModal.isUpdating}
          shift={this.props.timesheet}
        />
        <TimesheetInfo />
      </>
    );
  };

  timesheetForm = () => {
    const { isEmployee, siteId, payload } = this.props;
    return (
      <TimesheetEditForm
        openSubModal={this.openSubModal}
        updateCurrentShift={this.updatePayloadShift}
        areaRoleOptions={this.getAreaRoleOptions()}
        availableSites={this.getAvailableSiteOptions()}
        siteId={isEmployee && payload.site_id ? payload.site_id : siteId}
        isEmployee={isEmployee}
        user={this.user}
      />
    );
  };

  subModals = () => {
    const {
      timesheet: { pay_entries, user_id, area_id, role_id },
      timesheetModal: { isUpdating },
      siteId,
      setUser,
    } = this.props;

    return (
      <>
        <UserModal
          isOpened={this.state.activeSubModal === 'users'}
          closeModal={this.closeUsersSubModal}
          user_id={user_id}
          setUser={setUser}
          isActive={this.state.activeSubModal === 'users'}
          preselectedAreaRole={{
            area_id: area_id,
            role_id: role_id,
            site_id: siteId,
          }}
          type={'timesheet'}
          owner={null}
        />
        {this.getEditSubModal()}
      </>
    );
  };

  getAwardPayload = () => {
    const { payload, saveAwardBtn } = this.props;
    return saveAwardBtn
      ? {
          higher_duty: payload.higher_duty,
          higher_duty_start: payload.higher_duty_start,
          higher_duty_end: payload.higher_duty_end,
        }
      : {};
  };

  getPayload = (ignoreErrors: boolean = false) => {
    const { payload } = this.props;

    return {
      user_id: payload.user_id,
      id: payload.id,
      breaks: updateDatesOfBreaksForMidnightShifts(
        payload.breaks,
        payload.start
      ),
      start: payload.start,
      end: payload.end ? payload.end : moment(),
      area_id: payload.area_id,
      role_id: payload.role_id,
      site_id: this.props.siteId,
      ignore_errors: ignoreErrors,
      rostered_shift_id: payload.rostered_shift_id,
      is_approved: payload.is_approved,
      tags: payload.tags,
      notes: !payload.notes ? null : payload.notes.trim(),
      project_id: payload.project_id ? payload.project_id : null,
      ...this.getAwardPayload(),
    };
  };

  approveTimesheet = () => {
    this.props.changeShiftStatus(this.props.payload);
  };

  submitOverlappedApprove = () => {
    this.props.changeShiftStatus({
      ...this.props.payload,
      ignore_errors: true,
    });
  };

  submitModal = () => {
    const { payload, timesheet, canApprove, autoApproveTimesheets } =
      this.props;
    if (payload.id === '') {
      this.props.createTimesheet(this.getPayload());
    } else {
      if (
        canApprove &&
        doesTimesheetNeedApproval({ timesheet, autoApproveTimesheets })
      ) {
        this.setState({
          ...this.state,
          showApproveModal: true,
        });
      } else {
        this.props.updateTimesheet(this.getPayload(false));
      }
    }
  };

  openSubModal = (name: string) => {
    this.setState({
      ...this.state,
      activeSubModal: name,
    });
    if (name === 'awards') {
      this.props.restoreAwards();
    }
  };

  closeSubModal = () => {
    this.setState({
      ...this.state,
      activeSubModal: '',
    });
  };

  closeAwardsSubModal = () => {
    this.setState({
      ...this.state,
      activeSubModal: '',
    });
    this.props.restoreAwards();
  };

  makeHandleDeleteShift = (ignore_errors: boolean) => () => {
    const {
      deleteShift,
      timesheet: { id },
    } = this.props;

    deleteShift({
      id,
      ignore_errors,
    });
  };

  closeUsersSubModal = () => {
    if (this.props.timesheet.id === '') {
      this.closeSubModal();
    } else {
      this.setState({
        ...this.state,
        activeSubModal: 'edit',
      });
    }
  };

  updatePayloadShift = (s: TimesheetPayload) => {
    this.props.updatePayloadShift(s);
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const {
      timesheetModal: { isOpened },
    } = this.props;
    if (prevProps.payload !== this.props.payload) {
      this.validateForm();
    }
    if (!isOpened && prevProps.timesheetModal.isOpened !== isOpened) {
      this.closeSubModal();
      setTimeout(function () {
        scrollLock(false);
      }, 1000);
    }
  }

  validateForm = () => {
    const { payload } = this.props;
    const { role_id, area_id } = payload;
    const option = getSelectedAreaRole(this.getAreaRoleOptions(), payload);
    if (option && (option as any)[0]) {
      this.setState({
        ...this.state,
        isValid: (option as any)[0].value !== '__',
      });
    } else {
      this.setState({
        ...this.state,
        isValid: payload.user_id !== null && role_id !== '' && area_id !== '',
      });
    }
  };

  get user(): (ServerUserFields | ServerUserCurrent) | undefined {
    const {
      timesheet: { user_id },
      timesheetModal: { isCurrentUser },
      users,
      currentUser,
    } = this.props;

    if (user_id) {
      return isCurrentUser ? currentUser : users[user_id];
    }
  }

  getAvailableSiteOptions = () => {
    const options: DropDownOptions[] = [];
    if (this.user) {
      this.user.user_roles.forEach((role) => {
        options.push({
          label: role.site_name,
          value: role.site_id,
        });
      });
    }
    return _.uniqBy(options, 'value');
  };

  getAreaRoleOptions = () => {
    const {
      areas,
      roles,
      areasBySideId,
      siteId,
      timesheet: { user_id, id },
      payload: { site_id },
      isEmployee,
    } = this.props;

    const selectedSite = isEmployee && site_id ? site_id : siteId;
    const areasBySite = areasBySideId[selectedSite];
    if (user_id) {
      const options = getOptionsForCurrentUser(
        areasBySite,
        areas,
        roles,
        this.user
      );
      if (id && id !== '') {
        const { role_id, area_id } = this.props.timesheet;
        const additionalOptions = getTimesheetOptionsForCurrentUser(
          areas,
          roles,
          area_id,
          role_id,
          options
        );
        return [...options, ...additionalOptions];
      }
      return options;
    } else {
      return getOptionsForUnassignedRoster(areasBySite, roles);
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  timesheetModal: getTimesheetModal(state),
  timesheet: getCurrentTimesheet(state),
  payload: getTimesheetPayload(state),
  areasBySideId: activeAreasBySiteIdSelector(state),
  ...getAccountTree(state),
  users: getUserListResponse(state),
  errors: state.timesheetModal.timesheetModal.errors,
  currentUser: getCurrentUser(state),
  canApprove: hasPermissionSelector(state, 'roster.timesheet.edit.approve'),
  autoApproveTimesheets: getAutoApproveTimesheets(state),
  isAdding: state.timesheetModal.allowancesIsAdding,
  isDeleting: state.timesheetModal.allowanceIsDeleting,
  saveAwardBtn: canSaveAward(state),
});

const mapToDispatchProps: DispatchProps = {
  closeModal: BOX_TIMESHEET_SHIFT_MODAL_CLOSE,
  setUser: BOX_TIMESHEET_SHIFT_MODAL_SET_SHIFT_USER,
  updateTimesheet: BOX_TIMESHEET_SHIFT_MODAL_UPDATE,
  updatePayloadShift: BOX_TIMESHEET_SHIFT_MODAL_SET_PAYLOAD_PROPS,
  deleteShift: BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT,
  changeShiftStatus: BOX_TIMESHEET_SHIFT_MODAL_APPROVE_TIMESHEET,
  clearErrors: BOX_TIMESHEET_SHIFT_MODAL_CLEAR_ERRORS,
  updateApproveProperty: BOX_TIMESHEET_SHIFT_MODAL_TOGGLE_APPROVE,
  restoreAwards: BOX_TIMESHEET_SHIFT_MODAL_RESTORE_AWARDS,
  deleteAllowance: BOX_TIMESHEET_SHIFT_MODAL_DELETE_ALLOWANCE,
};

export default connect(mapStateToProps, mapToDispatchProps)(TimesheetModal);
