import { useEditDialogContext } from 'contexts';
import {
  AlertErrorBoxDialog,
  BreaksFieldGroup,
  CloseIcon,
  DateRangeFieldGroup,
  dateRangeFieldGroupUtils,
  DialogContentNarrow,
  NotesField,
  RoleField,
  ShiftSummaryBlock,
} from 'element';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Box,
} from 'extended-oxygen-elements';
import {
  useActions,
  useFormFields,
  useFormValidator,
  useSelectorWithProps,
  useSetFormField,
  useUpdateStatusEffect,
} from 'hooks';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_TIMESHEETS_WEEKLY_DIALOG_ERRORS_CLOSED,
  BOX_TIMESHEETS_WEEKLY_TIMESHEET_UPDATE,
  getDialogErrors,
  getDialogRequestStatus,
  getDialogUpdating,
} from 'state/TimesheetsWeeklyPage';
import { GET_PROJECTS_REQUEST } from 'state/Projects/actions';
import { userRolesBySiteSelector, userSelector } from 'state/UsersCollection';
import { RosterTimesheetListedStoppedWithPayEntries } from 'type';
import { ConnectedShiftField, ConnectedShiftFieldWrapper } from './components';
import createFormFields from './createFormFields';
import makePayload from './makePayload';
import validate from './validate';
import { getProjects } from 'state/Projects/selectors';
import { ServerProject } from 'type';
import { useFormControlSpacing } from 'hooks';
import { FeatureFlag } from 'element/feature-flags-components';

type TimesheetDialogFormProps = {
  timesheet: RosterTimesheetListedStoppedWithPayEntries;
};

export default EditDialogForm;

const emptyProject = { label: 'No project selected', value: '' };

export function EditDialogForm({ timesheet }: TimesheetDialogFormProps) {
  const dispatch = useDispatch();

  const projects = useSelector(getProjects);
  const incompleteProjects = projects.filter((project) => !project.completed);

  const projectOptions = incompleteProjects.map((project: ServerProject) => ({
    label: project.name,
    value: project.id,
  }));

  // add empty project option so the user can choose not to add a project/remove a project when editing
  const projectOptionsWithEmptyOption = [emptyProject, ...projectOptions];

  const [timesheetUpdate, dialogErrorsClosed] = useActions([
    BOX_TIMESHEETS_WEEKLY_TIMESHEET_UPDATE.request,
    BOX_TIMESHEETS_WEEKLY_DIALOG_ERRORS_CLOSED,
  ]);

  const loading = useSelector(getDialogUpdating);
  const errors = useSelector(getDialogErrors);
  const roleOptions = useSelectorWithProps(userRolesBySiteSelector, timesheet);
  const user = useSelectorWithProps(userSelector, timesheet.user_id);

  const { closeDialog } = useEditDialogContext();

  const { formFields, setFormFields } = useFormFields(() =>
    createFormFields(timesheet)
  );
  const { makeSetFormField } = useSetFormField(setFormFields);

  const [selectedProjectId, setSelectedProjectId] = useState<string>(
    timesheet.project_id || ''
  );

  const handleProjectChange = (event: React.ChangeEvent<{ value: string }>) => {
    setSelectedProjectId(event.target.value);
  };

  const { formErrors, submitForm } = useFormValidator(formFields, {
    loading,
    validate,
    makePayload,
    onSubmit: (payload) => {
      timesheetUpdate({
        ...payload,
        id: timesheet.id,
        user_id: timesheet.user_id,
        project_id: selectedProjectId,
      });
    },
  });
  useEffect(() => {
    dispatch(GET_PROJECTS_REQUEST());
  }, [dispatch, timesheet.id]);

  useUpdateStatusEffect(getDialogRequestStatus, {
    success: closeDialog,
    cancel: closeDialog,
  });

  const spacing = useFormControlSpacing('normal');

  return (
    <>
      <DialogTitle
        endAdornment={
          <IconButton disabled={loading} onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        }
      >
        Edit timesheet - {user.prefered_or_full_name}
      </DialogTitle>

      <DialogContent data-testid="timesheet-modal-content">
        <AlertErrorBoxDialog
          BoxProps={{
            mb: 2,
          }}
          errors={errors}
          onClose={dialogErrorsClosed}
          data-testid="timesheet-modal-error"
        />

        <DialogContentNarrow>
          <RoleField
            options={roleOptions}
            value={formFields.role}
            error={formErrors.role}
            onChange={makeSetFormField('role')}
          />
          <ConnectedShiftFieldWrapper
            {...formFields}
            timesheet_id={timesheet.id}
            user_id={timesheet.user_id}
            site_id={timesheet.site_id}
          >
            <ConnectedShiftField
              value={formFields.rostered_shift_id}
              onChange={makeSetFormField('rostered_shift_id')}
            />
          </ConnectedShiftFieldWrapper>

          <FeatureFlag name="projects">
            <FeatureFlag.On>
              <Box {...spacing}>
                <TextField
                  select
                  margin="none"
                  label="Project"
                  value={selectedProjectId}
                  onChange={handleProjectChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    readOnly: loading,
                    IconComponent: loading ? () => null : undefined,
                    disabled: projectOptionsWithEmptyOption.length < 2,
                  }}
                  data-testid={'timesheet-modal-connected-projects'}
                >
                  {projectOptionsWithEmptyOption.length < 2 ? (
                    <MenuItem value="">No projects available</MenuItem>
                  ) : (
                    projectOptionsWithEmptyOption.map(({ label, value }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              </Box>
            </FeatureFlag.On>
          </FeatureFlag>

          <Divider />
          <DateRangeFieldGroup
            values={formFields}
            onChange={setFormFields}
            errors={formErrors}
          />
          <Divider />
          <BreaksFieldGroup
            value={formFields.breaks}
            error={formErrors.breaks}
            onChange={makeSetFormField('breaks')}
          />
        </DialogContentNarrow>

        <Divider />

        <NotesField
          value={formFields.notes}
          error={formErrors.notes}
          onChange={makeSetFormField('notes')}
        />

        <ShiftSummaryBlock
          breaks={formFields.breaks}
          duration={dateRangeFieldGroupUtils.getDateRangeDurationMinutes(
            formFields
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button
          disabled={loading}
          onClick={closeDialog}
          data-testid={'timesheet-modal-cancel'}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          loading={loading}
          onClick={submitForm}
          data-testid={'timesheet-modal-submit'}
        >
          Save Timesheet
        </Button>
      </DialogActions>
    </>
  );
}
