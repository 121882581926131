import { createAction, createReducer } from 'lib/store-utils';
import { ExpectedFlag, expectedFlags, ExpectedFlagsKeys } from 'feature-flags';
import { StoreState } from 'state/types';
import { mapValues } from 'lodash';
import { Dictionary } from 'ts-essentials';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { processApiRequest } from '../ProcessApiRequest';
import { Api } from 'lib/Api';

export type FlagsReducerState = {
  flags: typeof expectedFlags | {};
};
export type FlagsUpdatedPayload = typeof expectedFlags | {};

export const BOX_FLAGS_UPDATED =
  createAction<FlagsUpdatedPayload>('Flags updated');

export const BOX_FLAGS_GET = createAction('Flags get');

const fetchFeatureFlags = function* (): SagaIterator {
  const response = yield call(processApiRequest, Api.FeatureFlags.get);
  if (response) {
    yield put(BOX_FLAGS_UPDATED(response));
  }
};

export const watchFeatureFlags = function* (): SagaIterator {
  yield takeLatest(BOX_FLAGS_GET, fetchFeatureFlags);
};

const defaultState: FlagsReducerState = {
  flags: {},
};

export const flags = createReducer<FlagsReducerState>({}, defaultState);

flags.on(
  BOX_FLAGS_UPDATED,
  (state, updatedFlags): FlagsReducerState => ({
    flags: {
      ...state.flags,
      ...updatedFlags,
    },
  })
);

const getState = (state: StoreState) => state.flags;
export const getFlags = (state: StoreState) => getState(state).flags;

export const getFlag = mapValues(
  expectedFlags,
  (flagName) => (state: StoreState) =>
    (getFlags(state) as any)[flagName as any] || false
) as Dictionary<(state: StoreState) => boolean, ExpectedFlagsKeys>;
