import { SagaIterator } from 'redux-saga';
import { call, fork, put, race, select, take } from 'redux-saga/effects';
import { pick } from 'lodash';
import { SagaAction, Timesheet } from 'type';
import { Api, TimesheetUpdateRequest, TimesheetUpdateResponse } from 'lib/Api';
import { formatError, getErrorStatus } from 'state/helpers';
import { processApiRequest } from 'state/ProcessApiRequest';
import * as isLoading from 'state/IsLoading';
import {
  BOX_TIMESHEET_UPDATE_ONE,
  getTimesheet,
} from 'state/TimesheetsCollection';
import { getChannelIsActive } from 'state/Sockets';
import { BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES } from 'state/Roster/Summary';
import {
  ConfirmForceModalTimesheetPayload,
  UpdateShiftTimePayload,
} from '../types';
import {
  BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_CLOSE,
  BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_OPEN,
  BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_SUBMIT,
  BOX_ROSTER_DAY_VIEW_ERROR_MODAL_OPEN,
} from '../actions';

const getTimesheetRelatedData = function* (data: Timesheet): SagaIterator {
  const isActiveChannel = yield select(getChannelIsActive, 'roster_week');
  if (!isActiveChannel) {
    yield put(BOX_TIMESHEET_UPDATE_ONE(data));
  }
  yield put(BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES());
};

export function* updateTimesheetTime({
  payload: { id, start, end },
}: SagaAction<UpdateShiftTimePayload>): SagaIterator {
  yield put(isLoading.BOX_IS_LOADING_GLOBAL_ON());
  const timesheet: Timesheet = yield select(getTimesheet, id);
  const optimisticallyUpdatedTimesheet: Timesheet = {
    ...timesheet,
    start,
    end,
  };

  yield put(BOX_TIMESHEET_UPDATE_ONE(optimisticallyUpdatedTimesheet));

  const payload: TimesheetUpdateRequest = {
    ...pick(timesheet, [
      'id',
      'user_id',
      'role_id',
      'area_id',
      'breaks',
      'higher_duty',
      'higher_duty_start',
      'higher_duty_end',
      'notes',
      'project_id',
    ]),
    start,
    end,
  };

  try {
    const data: TimesheetUpdateResponse = yield call(
      processApiRequest,
      Api.Timesheet.updateTimesheet,
      payload
    );

    yield call(getTimesheetRelatedData, data);
  } catch (error) {
    const errors = formatError(error);

    yield put(BOX_TIMESHEET_UPDATE_ONE(timesheet));

    if (getErrorStatus(error) === Api.Timesheet.OVERLAP_ERROR_STATUS) {
      yield fork(confirmForceUpdateTimesheet, { errors, payload });
    } else {
      yield put(BOX_ROSTER_DAY_VIEW_ERROR_MODAL_OPEN(errors));
    }
  }
  yield put(isLoading.BOX_IS_LOADING_GLOBAL_OFF());
}

export function* confirmForceUpdateTimesheet({
  payload,
  errors,
}: ConfirmForceModalTimesheetPayload): SagaIterator {
  yield put(BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_OPEN({ errors }));

  const { confirm } = yield race({
    confirm: take(BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_SUBMIT),
    close: take(BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_CLOSE),
  });

  if (confirm) {
    yield call(updateTimesheetTimeForce, payload);
  }
}

export function* updateTimesheetTimeForce(
  payload: TimesheetUpdateRequest
): SagaIterator {
  yield put(isLoading.BOX_IS_LOADING_ON('ROSTER_CONFIRM_OVERLAP_MODAL'));

  try {
    const data: TimesheetUpdateResponse = yield call(
      processApiRequest,
      Api.Timesheet.updateTimesheet,
      {
        ...payload,
        ignore_errors: true,
      }
    );

    yield call(getTimesheetRelatedData, data);
  } catch (error) {
    yield put(BOX_ROSTER_DAY_VIEW_ERROR_MODAL_OPEN(formatError(error)));
  }

  yield put(BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_CLOSE());
  yield put(isLoading.BOX_IS_LOADING_OFF('ROSTER_CONFIRM_OVERLAP_MODAL'));
}
